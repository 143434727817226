import { createNewrelicConfig } from '../modules/analytics/analytics.config';

export const environment = {
    production: true,
    BASE_URL: {
        API: 'https://api.{alias}/',
        FRONT: 'https://app.{alias}/',
        ASSETS: 'https://pd-brand-assets.s3-ap-southeast-2.amazonaws.com/',
        WIDGET: 'https://widget.{alias}',
    },
    CAPTCHA_KEY: '6Ldbl3kUAAAAAKZzmsJEyPzClDZV4yGIyXEi1qwk',
    DEFAULT_ALIAS: 'paydock.com',
    ENV_NAME: 'production',
    brand: 'paydock',
    countSubDomain: 1,
    domain: 'paydock.com',
    cookieConsent: true,
    newrelic: createNewrelicConfig({
        accountId: '974691',
        agentId: '1386153406',
        applicationId: '1386153406',
        licenseKey: '4848a32285',
        trustKey: '974691',
    }),
};
