import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProgressBarService {
    changeState$ = new BehaviorSubject({ count: 0, chankTime: 0 });
    stopProcess$ = new Subject();
    increaseStep$ = new BehaviorSubject(0);
    chanckTime: number;
    startChankTime: Date;

    show(count: number, chankTime: number = 3000) {
        this.startChankTime = new Date();
        this.changeState$.next({ count, chankTime });
    }

    hide() {
        this.changeState$.next({ count: 0, chankTime: 0 });
    }

    stopProcess(): void {
        this.stopProcess$.next();
    }

    next() {
        this.chanckTime = Math.abs(new Date().getTime() - this.startChankTime.getTime());
        this.increaseStep$.next(this.chanckTime);
        this.startChankTime = new Date();
    }
}
