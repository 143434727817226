import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiModule } from '../../api.module';
import { Resource } from '../resource';
import { Bulletin } from './bulletin.interface';

const ROUTE = {
    BULLETINS: '/bulletins',
    BULLETINS_BY_ID: '/bulletins/:id',
};

@Injectable({
    providedIn: ApiModule,
})
export class BulletinService extends Resource {
    getAll(): Observable<Bulletin[]> {
        return this.http.get<Bulletin[]>(this.getLink(ROUTE.BULLETINS));
    }

    remove(id: string): Observable<Bulletin> {
        return this.http.delete<Bulletin>(this.getLink(ROUTE.BULLETINS_BY_ID, { id }));
    }
}
