import { InjectionToken } from '@angular/core';

import { environment } from '../../../environments/environment';

type AppConfig = typeof environment;

const APP_CONFIG = new InjectionToken<AppConfig>('appConfig');

function getAppConfig(): AppConfig {
    return environment;
}

export { APP_CONFIG, getAppConfig };
export type { AppConfig };
