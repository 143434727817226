import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiModule } from '../../api.module';
import { Resource } from '../resource';
import { AuthInterface } from './auth.interface';

const ROUTE = {
    SPAWN: '/scope/:scope/auth/spawn',
    EXCHANGE_PROOF: '/auth/exchange-proof',
};

@Injectable({
    providedIn: ApiModule,
})
@Injectable()
export class AuthService extends Resource {
    constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    loginAs(id: string, scope: string): Observable<AuthInterface> {
        return this.http.post<AuthInterface>(this.getLink(ROUTE.SPAWN, { scope }), { scope_id: id });
    }

    exchangeProof(): Observable<AuthInterface> {
        return this.http.post<AuthInterface>(this.getLink(ROUTE.EXCHANGE_PROOF), {});
    }
}
