import { Base64Util } from './base64.util';
import { CryptoHelper } from './crypto.helper';
import { JsonSerializerHelper } from './json-serializer.helper';

export class TokenEncrypterHelper {
    private static readonly SERIALIZATION_KEY = 'TokenEncrypterHelper';

    static async encryptToken(token: string): Promise<string> {
        if (await this.isTokenEncrypted(token)) {
            return token;
        }
        const encryptedToken = await this.encrypt(token);
        const payload = { id: Base64Util.base64URLencode(encryptedToken) };
        return await JsonSerializerHelper.serialize(payload, this.SERIALIZATION_KEY);
    }

    static async decryptToken(token): Promise<string> {
        if (await this.isTokenEncrypted(token)) {
            const [, encodedPayload] = token.split('.');
            const payload = JSON.parse(Base64Util.base64URLdecode(encodedPayload));
            const encryptedToken = Base64Util.base64URLdecode(payload.id);
            return await this.decrypt(encryptedToken);
        }
        return token;
    }

    private static async isTokenEncrypted(token: string): Promise<boolean> {
        return JsonSerializerHelper.isValidSerializedJson(token, this.SERIALIZATION_KEY);
    }

    private static async encrypt(token: string): Promise<string> {
        const [header, payload, signature] = token.split('.');

        const encryptedPayload = await CryptoHelper.encryptData(payload, header);

        const encryptedPayloadString = Base64Util.base64Encode(JSON.stringify(encryptedPayload));

        const encryptedSignature = await CryptoHelper.encryptData(signature, encryptedPayloadString);

        const encryptedSignatureString = Base64Util.base64Encode(JSON.stringify(encryptedSignature));

        const encryptedHeader = await CryptoHelper.encryptData(header, encryptedSignatureString);

        const encryptedHeaderString = Base64Util.base64Encode(JSON.stringify(encryptedHeader));

        const encryptedToken = `${encryptedHeaderString}.${encryptedPayloadString}.${encryptedSignatureString}`;

        return encryptedToken;
    }

    private static async decrypt(encryptedToken: string): Promise<string> {
        const [encryptedHeaderString, encryptedPayloadString, encryptedSignatureString] = encryptedToken.split('.');

        const encryptedHeader = JSON.parse(Base64Util.base64Decode(encryptedHeaderString));
        const encryptedPayload = JSON.parse(Base64Util.base64Decode(encryptedPayloadString));
        const encryptedSignature = JSON.parse(Base64Util.base64Decode(encryptedSignatureString));

        const decryptedHeader = await CryptoHelper.decryptData(
            encryptedHeader.encryptedData,
            encryptedHeader.iv,
            encryptedSignatureString,
        );

        const decryptedSignature = await CryptoHelper.decryptData(
            encryptedSignature.encryptedData,
            encryptedSignature.iv,
            encryptedPayloadString,
        );

        const decryptedPayload = await CryptoHelper.decryptData(
            encryptedPayload.encryptedData,
            encryptedPayload.iv,
            decryptedHeader,
        );

        const token = `${decryptedHeader}.${decryptedPayload}.${decryptedSignature}`;

        return token;
    }
}
