import { Component, OnInit } from '@angular/core';
import { ceil } from 'lodash';

import { ProgressBarService } from '../../../services/progress-bar.service';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './app-progress-bar.component.html',
    styleUrls: ['./app-progress-bar.component.scss'],
})
export class AppProgressBarComponent implements OnInit {
    value = 0;
    isShow: boolean;
    interval: number;
    step: number;

    constructor(private readonly progressBarService: ProgressBarService) {}

    ngOnInit(): void {
        this.progressBarService.changeState$.subscribe((state: { count: number; chankTime: number }) => {
            state.count ? this.show(state.count, state.chankTime) : this.hide();
        });
        this.progressBarService.increaseStep$.subscribe((chunkTime: number) => {
            this.next(chunkTime);
        });
    }

    show(count: number, chunkTime: number): void {
        this.isShow = true;
        this.value = 0;
        this.step = ceil(100 / (ceil(count) + 1));
        this.interval = this.increaseValue(chunkTime || 3000, this.step, this.step);
    }

    hide(): void {
        window.clearInterval(this.interval);
        this.interval = this.increaseValue(3000, 100 - this.value, 100);
        window.setTimeout(() => {
            this.isShow = false;
            this.value = 0;
            clearInterval(this.interval);
        }, 3000);
    }

    next(chunkTime: number): void {
        window.clearInterval(this.interval);
        this.interval = this.increaseValue(chunkTime, this.step, this.value + this.step);
    }

    increaseValue(time: number, count: number, maxCount: number): number {
        return window.setInterval(() => {
            if (this.value >= maxCount || this.value >= 99) return window.clearInterval(this.interval);

            this.value++;
        }, time / count);
    }

    stopProcess(): void {
        this.progressBarService.stopProcess();
        window.clearInterval(this.interval);
        this.isShow = false;
        this.value = 0;
    }
}
