import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Uri } from '../../../main/helper/uri';
import { ApiModule } from '../../api.module';
import { LINK_TYPE, Resource } from '../resource';
import { ViewSettings } from './view-settings.interfase';

const ROUTE = {
    SETTINGS: 'settings/view.json',
    CREATE: '/scope/brand/view-settings',
    GET: '/scope/brand/view-settings',
    APPLY: '/scope/brand/view-settings/apply',
};

@Injectable({
    providedIn: ApiModule,
})
export class ViewSettingsService extends Resource {
    hostname: string;

    constructor(@Inject(DOCUMENT) protected document: Document, protected httpClient: HttpClient) {
        super(httpClient);
        this.hostname = this.document.location.host;
    }

    getAssets(): Observable<ViewSettings> {
        return this.http.get<ViewSettings>(this.getLink(this.generateS3Url(), {}, LINK_TYPE.ASSETS));
    }

    get(): Observable<ViewSettings> {
        return this.http.get<ViewSettings>(this.getLink(ROUTE.GET));
    }

    create(bardSettings: ViewSettings): Observable<ViewSettings> {
        return this.http.post<ViewSettings>(this.getLink(ROUTE.CREATE), bardSettings);
    }

    apply(): Observable<ViewSettings> {
        return this.http.post<ViewSettings>(this.getLink(ROUTE.APPLY), {});
    }

    generateS3Url() {
        return `${environment.ENV_NAME}/${Uri.extractAppAlias(this.hostname, environment.countSubDomain)}/${
            ROUTE.SETTINGS
        }`;
    }
}
