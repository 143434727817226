import { Base64Util } from './base64.util';

export class JsonSerializerHelper {
    private static readonly ALGORITHM = 'HS256';
    private static readonly TYPE = 'SERIALIZED_JSON';
    private static readonly ALGORITHM_NAME = 'HMAC';
    private static readonly HASH = 'SHA-256';

    static async serialize(json: Object, key: string): Promise<string> {
        const header = {
            typ: this.TYPE,
            alg: this.ALGORITHM,
        };

        const encodedHeader = Base64Util.base64URLencode(JSON.stringify(header));

        const encodedPayload = Base64Util.base64URLencode(JSON.stringify(json));

        const headerPayload = `${encodedHeader}.${encodedPayload}`;

        const signature = await this.sign(headerPayload, key);

        const serialized = `${headerPayload}.${signature}`;

        return serialized;
    }

    static async isValidSerializedJson(serialized: string, key: string): Promise<boolean> {
        if (!serialized) return false;
        const [encodedHeader, encodedPayload, encodedSignature] = serialized.split('.');

        const headerPayload = `${encodedHeader}.${encodedPayload}`;

        const signature = await this.sign(headerPayload, key);

        if (signature !== encodedSignature) return false;

        return true;
    }

    private static async sign(data: string, secret: string) {
        const encodedKey = new TextEncoder().encode(secret);

        const key = await crypto.subtle.importKey(
            'raw',
            encodedKey,
            { name: this.ALGORITHM_NAME, hash: this.HASH },
            false,
            ['sign'],
        );

        const signature = await crypto.subtle.sign(
            { name: this.ALGORITHM_NAME, hash: { name: this.HASH } },
            key,
            new TextEncoder().encode(data),
        );

        const encodedSignature = Base64Util.base64URLencode(String.fromCharCode.apply(null, new Uint8Array(signature)));

        return encodedSignature;
    }
}
