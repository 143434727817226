<div class="row">
    <div class="col-1 pt-1">
        <button
            class="btn-minus"
            aria-label="Remove custom field"
            (click)="onDeleteClick()"
            *ngIf="!isHiddenRemoveButton">
            <i class="fa fa-minus" aria-hidden="true"></i>
        </button>
    </div>
    <div class="form-group col-5">
        <p-autoComplete
            dynamicAttribute
            [dynamicAttributeConfig]="dynamicAttributeConfig"
            ariaLabel="Custom field name"
            [(ngModel)]="customField.key"
            #key="ngModel"
            [attr.aria-invalid]="key.invalid"
            [attr.aria-labelledby]="key.invalid ? 'key_error' : null"
            aria-live="polite"
            [suggestions]="filteredFields"
            (completeMethod)="filterField($event)"
            minlength="2"
            maxlength="40"
            (ngModelChange)="onChange()"
            [dropdown]="true"
            [required]="!!customField.value"
            [pattern]="keyPatternValidation ? REGEX.OBJECT_KEY : false"
            panForbidden>
            <ng-template let-field pTemplate="item">
                <div class="ui-helper-clearfix">
                    <div>{{ field }}</div>
                </div>
            </ng-template>
        </p-autoComplete>
        <div class="alert alert-danger" *ngIf="!!customField.value && key.pristine">This field is required</div>
        <form-errors [field]="key" id="key_error">
            <form-error type="required">This field is required</form-error>
            <form-error type="pattern">Please enter valid value</form-error>
            <form-error type="minlength">This field must be at least 2 characters long.</form-error>
            <form-error type="maxlength">This field must be at most 40 characters long.</form-error>
            <form-error type="panForbidden">PAN detected in an unsupported field.</form-error>
        </form-errors>
    </div>
    <div class="form-group col-6">
        <input
            class="form-control"
            name="value"
            #value="ngModel"
            [attr.aria-invalid]="value.invalid"
            [attr.aria-labelledby]="value.invalid ? 'value_error' : null"
            aria-live="polite"
            minlength="2"
            maxlength="128"
            aria-label="Custom field value"
            [(ngModel)]="customField.value"
            (ngModelChange)="onChange()"
            [required]="!!customField.key"
            panForbidden />
        <div class="alert alert-danger" *ngIf="!!customField.key && value.pristine">This field is required</div>
        <form-errors [field]="value" id="value_error">
            <form-error type="required">This field is required</form-error>
            <form-error type="minlength">This field must be at least 2 characters long.</form-error>
            <form-error type="maxlength">This field must be at most 128 characters long.</form-error>
            <form-error type="panForbidden">PAN detected in an unsupported field.</form-error>
        </form-errors>
    </div>
</div>
