import { Base64Util } from './base64.util';

export class CryptoHelper {
    static readonly ALGORITHM_NAME = 'AES-GCM';
    static readonly KEY_ALGORITHM = 'SHA-256';
    static readonly IV_SIZE = 12;

    static async encryptData(data: string, secret: string): Promise<{ encryptedData: string; iv: string }> {
        const key = await this.getKey(secret);
        const iv = crypto.getRandomValues(new Uint8Array(this.IV_SIZE));

        const encodedData = new TextEncoder().encode(data);

        const encryptedData = await crypto.subtle.encrypt(
            {
                name: this.ALGORITHM_NAME,
                iv: iv,
            },
            key,
            encodedData,
        );

        return {
            encryptedData: Base64Util.base64Encode(String.fromCharCode.apply(null, new Uint8Array(encryptedData))),
            iv: Base64Util.base64Encode(String.fromCharCode.apply(null, iv)),
        };
    }

    static async decryptData(encryptedDataString: string, ivString: string, secret: string): Promise<string> {
        const key = await this.getKey(secret);
        const encryptedData = Uint8Array.from(Base64Util.base64Decode(encryptedDataString), c => c.charCodeAt(0));
        const iv = Uint8Array.from(Base64Util.base64Decode(ivString), c => c.charCodeAt(0));

        const decryptedData = await crypto.subtle.decrypt(
            {
                name: this.ALGORITHM_NAME,
                iv: iv,
            },
            key,
            encryptedData,
        );

        return new TextDecoder().decode(decryptedData);
    }

    private static async getKey(secret: string): Promise<CryptoKey> {
        const encoder = new TextEncoder();
        const parameterBytes = encoder.encode(secret);

        const hashBuffer = await crypto.subtle.digest(this.KEY_ALGORITHM, parameterBytes);

        return await crypto.subtle.importKey('raw', hashBuffer, { name: this.ALGORITHM_NAME }, true, [
            'encrypt',
            'decrypt',
        ]);
    }
}
