import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { get, includes, some } from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CookieConsentService } from 'src/modules/analytics/cookie-consent/cookie-consent.service';

import { SubAccountsInterface } from '../../../access/interfaces/sub-accounts.interface';
import { AccessService } from '../../../access/services/access.service';
import { SubAccountsService } from '../../../access/services/sub-accounts.service';
import { MetricsService } from '../../../api/resources/metrics/metrics.service';
import { User } from '../../../api/resources/user/user.interface';
import { UserService } from '../../../api/resources/user/user.servise';
import { ViewSettings } from '../../../api/resources/view-settings/view-settings.interfase';
import { BRAND } from '../../helper/constants/brand.constants';
import { PERMISSIONS_MENU_GROUPS, SCOPE } from '../../helper/constants/permissions.constants';
import { SIDEBAR_MENU_LINK, USER_MENU_LINKS } from '../../helper/constants/sidebar.constants';
import { MESSAGE_TYPE, MessagePopupService } from '../../services/message-popup.service';
import { SharedGroupService } from '../../services/shared-group.service';
import { ViewSettingsSharingService } from '../../services/view-settings-sharing.service';

const LOGO_LINK = {
    global: '/brands',
    brand: '/merchants',
    company: '/dashboard/welcome',
};

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
    readonly SIDEBAR_MENU_LINK = SIDEBAR_MENU_LINK;
    readonly PERMISSIONS_MENU_GROUPS = PERMISSIONS_MENU_GROUPS;
    readonly USER_MENU_LINKS = USER_MENU_LINKS;
    readonly BRAND = BRAND;
    readonly SCOPE = SCOPE;

    hidedUserMenu = true;
    subAccount: SubAccountsInterface;
    user = {} as User;
    viewSettings: ViewSettings = {} as ViewSettings;
    logoLink: string;
    accountName = '';
    scope: SCOPE;

    private readonly unsubscribe$ = new Subject<void>();

    constructor(
        private readonly cookieConsentService: CookieConsentService,
        private userService: UserService,
        private router: Router,
        private viewSettingsSharingService: ViewSettingsSharingService,
        private subAccountsService: SubAccountsService,
        private metricsService: MetricsService,
        private accessService: AccessService,
        private readonly messagePopupService: MessagePopupService,
    ) {}

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        this.scope = this.accessService.identifyScope();
        this.logoLink = LOGO_LINK[this.scope];
        this.toggleUserMenuFromRout();
        this.userService.getCurrent().subscribe((user: User) => {
            this.user = user;
            this.userService.setUser(user);
            this.subAccount = this.subAccountsService.getSubAccount();
            this.accountName = this.getName();
        });
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => this.toggleUserMenuFromRout());

        this.viewSettingsSharingService.currentSettings.pipe(takeUntil(this.unsubscribe$)).subscribe(viewSettings => {
            this.viewSettings = viewSettings;
        });
        this.subAccount = this.subAccountsService.getSubAccount();
    }

    logoClick(): void {
        if (this.scope !== SCOPE.GLOBAL || !this.isSLAavailable()) {
            return void this.router.navigateByUrl(this.logoLink);
        }

        this.metricsService
            .getGlobalResponseTime()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: result => {
                    window.open(result.url, '_blank');
                },
                error: error => {
                    this.messagePopupService.showMessage(get(error.error, 'error'), MESSAGE_TYPE.ERROR);
                },
            });
    }

    routeIsEquivalent(route: string): boolean {
        return window.location.pathname.includes(route);
    }

    get helpLinksAvailable() {
        return this.viewSettings && this.viewSettings.lower_links && this.viewSettings.lower_links.length;
    }

    get personalSettingsAvailable() {
        return this.cookieConsentService.canShowCookieConsent;
    }

    get logoAltText(): string {
        switch (this.scope) {
            case SCOPE.GLOBAL:
                return this.isSLAavailable() ? 'Link to the SLA report' : 'Link to the Brands page';
            case SCOPE.BRAND:
                return 'Link to the Merchants page';
            case SCOPE.COMPANY:
            default:
                return 'Link to the Welcome page';
        }
    }

    getName(): string {
        if (this.subAccount.isSubAccount) return this.subAccount.subAccountName;

        return this.user.first_name != null &&
            this.user.last_name != null &&
            (this.user.first_name || this.user.last_name)
            ? this.user.first_name + ' ' + this.user.last_name
            : this.user.email;
    }

    logOut(): void {
        this.userService.logOut().subscribe(() => {
            SharedGroupService.deleteShared();
            this.router.navigate(['auth', 'sign-in']);
        });
    }

    toggleUserMenuFromRout(): void {
        this.hidedUserMenu = !some(USER_MENU_LINKS, value => includes(this.router.url, value));
    }

    showCookieConsentModal() {
        this.cookieConsentService.showConsentModal();
    }

    switchBack(): void {
        this.subAccountsService.switchBack().subscribe();
    }

    private isSLAavailable(): boolean {
        return this.accessService.isAllowedPermission('metrics:global-create-global-response-time-sli-static-chart');
    }
}
