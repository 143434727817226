import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'equalMessageType',
})
export class EqualMessageTypePipe implements PipeTransform {
    transform(toasterType: string, comparableType: string): boolean {
        return toasterType === comparableType;
    }
}
