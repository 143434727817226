import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { METRICS_STORAGE_KEY } from '../../../main/helper/constants/local-storage.constant';
import { ApiModule } from '../../api.module';
import { Resource } from '../resource';
import { Metric } from './metrics.interface';

const ROUTE = {
    RESPONSE_TIME: '/scope/global/metrics/charts/global-response-time/sli/static',
};

@Injectable({
    providedIn: ApiModule,
})
export class MetricsService extends Resource {
    public getGlobalResponseTime(): Observable<Metric> {
        const localData = localStorage.getItem(METRICS_STORAGE_KEY.GLOBAL_RESPONSE_TIME);

        if (localData) {
            return of(JSON.parse(localData));
        }

        return this.http
            .post<Metric>(this.getLink(ROUTE.RESPONSE_TIME), {
                duration_type: 'internal',
                format: 'pdf',
            })
            .pipe(
                tap(data => {
                    localStorage.setItem(METRICS_STORAGE_KEY.GLOBAL_RESPONSE_TIME, JSON.stringify(data));
                }),
            );
    }

    public clearLocalData() {
        const storageKeys = Object.values(METRICS_STORAGE_KEY);

        storageKeys.forEach(key => localStorage.removeItem(key));
    }
}
