export enum PaydockUrlSegments {
    Root = '',
    Account = 'account',
    Dashboard = 'dashboard',
    Gateways = 'gateways',
    Connected = 'connected',
    List = 'list',
    Customers = 'customers',
    Subscriptions = 'subscriptions',
    RemoteAction = 'remote-action',
    Config = 'config',
    StyleConfig = 'style-config',
    Vault = 'vault',
    LimitProfiles = 'limit-profiles',
    Rules = 'rules',
    Charges = 'charges',
    Merchants = 'merchants',
    Transactions = 'transactions',
    Brands = 'brands',
    BrandUsers = 'brand-users',
    BrandStyles = 'brand-styles',
    MyBrand = 'my-brand',
    MyCompany = 'my-company',
    Company = 'company',
    VaultDisplay = 'vault-display',
    SharedGroups = 'shared-groups',
    Proxy = 'proxy',
    SubAccounts = 'sub-accounts',
    FeeProfile = 'fee-profile',
    FeeHistory = 'fee-history',
    GlobalUsers = 'global-users',
    Notifications = 'notifications',
    NoPermissions = 'no-permissions',
    Services = 'services',
    SchemaRegistries = 'schema-registries',
    Registration = 'registration',
    Auth = 'auth',
    Saml = 'saml',
    SignIn = 'sign-in',
}
