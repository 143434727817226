import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { AccessService } from '../../../../access/services/access.service';

@Component({
    selector: 'app-no-permissions-page',
    template: '',
})
export class NoPermissionsPageComponent implements OnInit {
    constructor(private accessService: AccessService, private activatedRoute: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
            if (queryParams['refresh_needed']) this.accessService.showRedirectWindow();
            this.router.navigate([], { queryParams: { refresh_needed: null }, queryParamsHandling: 'merge' });
        });
    }
}
