import { Injectable } from '@angular/core';
import { chain } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { Schema, SchemaElement } from '../../api/resources/schema-registries/schema-registries.interface';

@Injectable({
    providedIn: 'root',
})
export class SchemasSharingService {
    behaviorSubject = new BehaviorSubject<Schema[]>(undefined);
    schemas = this.behaviorSubject.asObservable();

    setSchemas(schemas: Schema[]) {
        this.behaviorSubject.next(schemas);
    }

    extractElements(schema: Schema): { [key: string]: SchemaElement }[] {
        return chain(schema)
            .get('definition.0.elements')
            .reduce((acc, element: SchemaElement) => {
                return { ...acc, [element.name]: element };
            }, {})
            .value() as { [key: string]: SchemaElement }[];
    }
}
