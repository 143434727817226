import { last, padEnd } from 'lodash';

export class Base64Util {
    public static base64Encode(utfString: string): string {
        return btoa(utfString);
    }

    public static base64Decode(base64String): string {
        return atob(base64String);
    }

    public static base64URLencode(str) {
        const base64Encoded = btoa(str);
        return base64Encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }

    public static base64URLdecode(str) {
        const paddedStr = padEnd(str, Math.ceil(str.length / 4) * 4, '=');
        return atob(paddedStr.replace(/-/g, '+').replace(/_/g, '/'));
    }

    public static fileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(last(String(reader.result).split('base64,')));
            reader.onerror = error => reject(error);
        });
    }
}
