import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { CookieConsentService } from 'src/modules/analytics/cookie-consent/cookie-consent.service';

import { AccessService } from '../../../access/services/access.service';
import { Schema } from '../../../api/resources/schema-registries/schema-registries.interface';
import { SchemaRegistriesService } from '../../../api/resources/schema-registries/schema-registries.service';
import { ViewSettings } from '../../../api/resources/view-settings/view-settings.interfase';
import { ViewSettingsService } from '../../../api/resources/view-settings/view-settings.service';
import { ModalShowable } from '../../interfaces/modal-showable.interface';
import { AjaxLoaderService } from '../../services/ajax-loader.service';
import { SchemasSharingService } from '../../services/schemas-sharing-service';
import { ThemeService } from '../../services/theme.service';
import { ViewSettingsSharingService } from '../../services/view-settings-sharing.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    showLoader: boolean;
    viewSettings: ViewSettings;
    expiryDate: string;

    @ViewChild('modalInfo') modalInfo: ModalShowable;
    @ViewChild('noPermissionModal') noPermissionModal: ModalShowable;

    constructor(
        private ajaxLoaderService: AjaxLoaderService,
        private cdr: ChangeDetectorRef,
        private title: Title,
        private viewSettingsService: ViewSettingsService,
        private themeService: ThemeService,
        private accessService: AccessService,
        private viewSettingsSharingService: ViewSettingsSharingService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private schemaRegistriesService: SchemaRegistriesService,
        private schemasSharingService: SchemasSharingService,
        private cookieConsentService: CookieConsentService,
    ) {}

    ngOnInit(): void {
        this.accessService.redirectWindow$.subscribe(() => {
            this.noPermissionModal.show();
        });
        combineLatest([
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map(route => {
                    while (route.firstChild) route = route.firstChild;
                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data),
                map(event => event['title']),
            ),
            this.viewSettingsService.getAssets().pipe(catchError(() => of(null))),
        ]).subscribe(([routeTitle, viewSettings]: [string, ViewSettings]) => {
            if (viewSettings) {
                this.viewSettings = viewSettings;
                this.viewSettingsSharingService.setSettings(this.viewSettings);
                if (this.viewSettings.style) this.themeService.setStyle(this.viewSettings.style);
            }
            const viewSettingsTitle = this.viewSettings?.text?.title;

            this.title.setTitle([routeTitle, ...(viewSettingsTitle ? [viewSettingsTitle] : [])].join(' | '));
        });

        this.schemaRegistriesService
            .extractUiSchemas()
            .pipe(
                catchError(() => {
                    return of(null);
                }),
            )
            .subscribe((schemas: Schema[]) => {
                if (schemas && schemas.length) {
                    this.schemasSharingService.setSchemas(schemas);
                }
            });

        this.ajaxLoaderService.changeState$.subscribe(state => {
            this.showLoader = state;
            this.cdr.detectChanges();
        });

        this.expiryDate = this.accessService.getPasswordExpiresAt()
            ? moment(this.accessService.getPasswordExpiresAt()).format('DD.MM.YYYY, h:mmA')
            : '';
        if (this.expiryDate && !this.accessService.hasShowedExpiryPassword()) {
            this.modalInfo.show();
            this.accessService.updatePasswordExpiryAlertFlag(true);
        }
    }

    ngAfterViewInit(): void {
        this.cookieConsentService.init();
    }

    onAccept(): void {
        this.router.navigate(['account', 'personal-details']);
    }
}
