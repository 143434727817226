import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { has } from 'lodash';

import { REGEX } from '../../../helper/constants/regex.constants';
import { CustomField } from '../custom-fields-list/custom-fields-list.component';

@Component({
    selector: 'app-custom-fields',
    templateUrl: './custom-fields.component.html',
    styleUrls: ['./custom-fields.component.scss'],
})
export class CustomFieldsComponent implements OnChanges {
    readonly REGEX = REGEX;
    readonly dynamicAttributeConfig = [
        {
            selector: '.ui-autocomplete-dropdown',
            attribute: 'aria-label',
            attributeValue: 'Open suggestions',
        },
    ];

    filteredFields: any[];

    @Input() possibleFields: string[] = [];
    @Input() keyPatternValidation = true;

    @Input() customField: CustomField;
    @Input() isHiddenRemoveButton: boolean;

    @Output() deleteCustomField = new EventEmitter<CustomField>();
    @Output() newCustomField = new EventEmitter<CustomField>();
    @Output() changeCustomField = new EventEmitter<void>();

    ngOnChanges(changes: SimpleChanges): void {
        if (has(changes, 'customFieldsItem')) setTimeout(() => this.onChange(), 400);
    }

    onDeleteClick() {
        this.deleteCustomField.emit(this.customField);
    }

    onChange() {
        this.changeCustomField.emit();
        this.newCustomField.emit(this.customField);
    }

    filterField(event) {
        this.filteredFields = this.possibleFields.filter(
            (field: string) => field.indexOf(event.query.toLowerCase()) === 0,
        );
    }
}
