export const REGEX = {
    INTEGER: '[0-9]*$',
    INTEGER_FOR_MATCH: /^\d+$/,
    NUMERIC: '[+-]?([0-9]*[.])?[0-9]+',
    NUMERIC_WITHOUT_NULL: '^[1-9][0-9]*$',
    NUMERIC_WITH_TWO_DECIMALS: '^[0-9]+(.[0-9]{1,2})?$',
    ALPHA_NUMERIC: '[a-zA-Z0-9]+',
    ALPHA_NUMERIC_WITH_DOTS_AND_SPACES: /^([a-zA-Z0-9.]\s*)+$/,
    PHONE_PATTERN: '^\\+[1-9]{1}[0-9]{3,14}$',
    ONLY_LETTER_PATTERN: '^[a-zA-Z\\s]*$',
    NAME_PATTERN: '^[^\\s]+[\\d\\w\\D\\s]*$',
    CARD_MASK: [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
    ],
    CARD_AND_CARD_MASK: '^[*0-9\\s]{13,19}$',
    WEB_SITE:
        '(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+' +
        '[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]\\.[^\\s]{2,})',
    WEB_SITE_HTTP:
        /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/,
    LAST_NUMBER_CARD_MASK: [/\d/, /\d/, /\d/, /\d/],
    // prettier-ignore
    AMOUNT: /^(?=.*[1-9])(0|[1-9]\d{0,11})(?:\.\d{1,3})?$/,
    PASSWORD_STRENGTH: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{14,})/gm,
    EMAIL: /^[a-zA-Z0-9][a-zA-Z0-9\._+\-]{0,128}@[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,6}$/,
    IP: '^(([1-9]?\\d|1\\d\\d|2[0-4]\\d|25[0-5])(\\.(?!$)|(?=$))){4}$',
    COLOR: /(^rgb\((\d+),\s*(\d+),\s*(\d+)\)$)|(^rgba\((\d+),\s*(\d+),\s*(\d+)(,\s*\d+\.(\d+))*\)|(^#[0-9A-Fa-f]{6})$)/,
    MONGO_ID: /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i,
    NOT_MONGO_ID: /^(?!(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)).+/i,
    DESCRIPTOR: '^(?!.*[<>\'"*])(?=.*[a-zA-Z]).+$',
    UUID: '\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b',
    OBJECT_KEY: '^([a-zA-Z0-9._@-])+$',
    PROTOCOL: '(^\\w+:|^)\\/\\/',
    URL_LAST_SLASH: '\\/+$',
    DOMAIN: '^(^http[s]?\\:\\/\\/)?([a-z0-9]+(-[a-z0-9]+)*\\.)+[a-z]{2,}(/)?$',
    WITHOUT_COLON_AND_SPACES: /^[^:\s]+$/,
    WITHOUT_SPACES: /\S/,
    HTTP_URL: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    HTTPS_URL: /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    PHONE_DESTINATION: /(^\+[1-9]\d{1,14}$)|(^\{\{PHONE\}\}$)/,
    EMAIL_DESTINATION: /(^[a-zA-Z0-9][a-zA-Z0-9._+-]{0,128}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$)|(^\{\{EMAIL\}\}$)/,
    TFA_CODE: /[0-9]{6}/,
    PGP_KEY:
        /^(-----BEGIN PGP PUBLIC KEY BLOCK-----).*([a-zA-Z0-9//\n\/\.\:\+\ \=]+).*(-----END PGP PUBLIC KEY BLOCK-----)$|^(-----BEGIN PGP PRIVATE KEY BLOCK-----).*([a-zA-Z0-9//\n\/\.\:\+\ \=]+).*(-----END PGP PRIVATE KEY BLOCK-----)$/, //#gitleaks:allow
    HOSTNAME:
        '^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\\-]*[A-Za-z0-9])$',
    STORAGE_SERVICE_NAME: /^[\w][\w@. -]{2,99}$/,
    PAN_PATTERN: /(?:\d[ -]*?){12,}\d/g,
    TEXT_SEGMENT: /^[a-zA-Z0-9_\-:;]+$/,
};
