import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AnalyticsService } from './analytics.service';
import { CookieConsentService } from './cookie-consent/cookie-consent.service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
})
export class AnalyticsModule {
    /** @ts-expect-error ensure these services are instantiated even if they are not used explicitly in our application */
    constructor(private cookieConsentService: CookieConsentService, private analyticsService: AnalyticsService) {}
}
