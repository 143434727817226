import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AccessService } from '../../../access/services/access.service';
import { SubAccountsSharedService } from '../../../access/services/sub-accounts-shared.service';
import { REDIRECT_ROUTES } from '../../../auth/constants/redirect-routes.constant';
import { ROUTES_PERMISSIONS_LIST } from '../../../auth/constants/routes-permissions-list.contant';
import { SCOPE } from '../../../main/helper/constants/permissions.constants';

@Injectable({ providedIn: 'root' })
export class PermissionRedirectService {
    constructor(
        private accessService: AccessService,
        private router: Router,
        private subAccountsSharedService: SubAccountsSharedService,
    ) {}

    redirectToDefaultPage(): void {
        this.router.navigate([this.getLinkToRedirect()]);
    }

    getLinkToRedirect(isRefreshNeeded = false): string {
        if (this.accessService.identifyScope() === SCOPE.COMPANY) {
            return REDIRECT_ROUTES.WELCOME_PAGE;
        }

        for (const route of ROUTES_PERMISSIONS_LIST) {
            if (this.accessService.isAllowedOneOfPermissionsGroup(route.value)) {
                return route.key;
            }
        }
        if (this.subAccountsSharedService.isSubAccount)
            return isRefreshNeeded
                ? `${REDIRECT_ROUTES.NO_PERMISSIONS}?refresh_needed=true`
                : REDIRECT_ROUTES.NO_PERMISSIONS;
        else
            return isRefreshNeeded
                ? `${REDIRECT_ROUTES.PERSONAL_DETAILS}?refresh_needed=true`
                : REDIRECT_ROUTES.PERSONAL_DETAILS;
    }

    redirectBySubAccount(): void {
        if (this.subAccountsSharedService.isSubAccount) void this.router.navigate([REDIRECT_ROUTES.NO_PERMISSIONS]);
        else void this.router.navigate([REDIRECT_ROUTES.PERSONAL_DETAILS]);
    }
}
