import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { get } from 'lodash';
import { from } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { AuthInterface, FeatureOptionsList } from '../../api/resources/auth/auth.interface';
import { AuthService } from '../../api/resources/auth/auth.service';
import { PermissionRedirectService } from '../../api/resources/auth/permission-redirect.service';
import { UserService } from '../../api/resources/user/user.servise';
import { ACCESS_STORAGE_KEY } from '../../main/helper/constants/local-storage.constant';
import { SCOPE } from '../../main/helper/constants/permissions.constants';
import { AjaxLoaderService } from '../../main/services/ajax-loader.service';
import { SharedGroupService } from '../../main/services/shared-group.service';
import { SubAccountsInterface } from '../interfaces/sub-accounts.interface';
import { AccessService } from './access.service';

@Injectable({
    providedIn: 'root',
})
export class SubAccountsService {
    constructor(
        private authService: AuthService,
        private accessService: AccessService,
        private userService: UserService,
        private ajaxLoaderService: AjaxLoaderService,
        private permissionRedirectService: PermissionRedirectService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    loginAsSubAccount(id: string, scope: SCOPE): void {
        this.authService
            .loginAs(id, scope)
            .pipe(
                this.ajaxLoaderService.operate(),
                switchMap((auth: AuthInterface) => {
                    if (!auth) return;

                    SharedGroupService.updateSharedComponent(auth.shared_permissions, auth.shared_privileges);
                    SharedGroupService.updateSharedCompaniesData(
                        auth.shared_companies && auth.shared_companies.length ? auth.shared_companies : [],
                    );

                    return from(this.accessService.switchToSubAccount(auth, scope)).pipe(
                        tap(() => {
                            localStorage.removeItem(ACCESS_STORAGE_KEY.DRAFT_REPORT_ID);
                            this.document.location.href = `/v2/${this.permissionRedirectService.getLinkToRedirect(
                                true,
                            )}`;
                        }),
                    );
                }),
            )
            .subscribe();
    }

    switchBack() {
        const accessData = this.accessService.switchBackSubAccount();
        const scope = Object.keys(accessData)[0];
        return this.userService.logOutByToken(accessData[scope].token).pipe(
            take(1),
            this.ajaxLoaderService.operate(),
            tap(() => {
                localStorage.removeItem(ACCESS_STORAGE_KEY.DRAFT_REPORT_ID);
                this.document.location.href = `/v2/${this.permissionRedirectService.getLinkToRedirect(true)}`;
            }),
        );
    }

    isSubAccount(): boolean {
        return this.accessService.isSubAccountAccess();
    }

    getAvailableFeatureOptionsOfHighestScope(): FeatureOptionsList | undefined {
        if (!this.isSubAccount()) {
            return this.accessService.getAvailableFeatures();
        }

        const tempAccessData = AccessService.getTempAccessData();

        return get(tempAccessData.global || tempAccessData.brand, 'avalable_features_options') as FeatureOptionsList;
    }

    getSubAccount(): SubAccountsInterface {
        if (!this.isSubAccount()) {
            return { isSubAccount: false, message: '', backTo: '' };
        }

        const tempAccessData = AccessService.getTempAccessData();

        const isGlobal = tempAccessData.global && !tempAccessData.brand;
        const subAccountName = AccessService.getAccessData().sub_account_name;
        return {
            isSubAccount: true,
            message: isGlobal ? 'You are logged in with a Brand Account' : 'You are logged in with a Merchant Account',
            backTo: isGlobal ? 'Back to Global' : 'Back to Master Merchant',
            subAccountName,
        };
    }
}
