import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { every } from 'lodash';

import { AccessService } from '../../access/services/access.service';

enum HIDING_METHODS {
    HIDE = 'hide',
    DISABLE = 'disable',
}
@Directive({
    selector: '[ifAllow], [ifDeny]',
})
export class PermissionsDirective implements OnInit {
    _allowed = true;
    _permission: string;
    _permissions: string;
    _oneOfPermissions: string;
    _oneOfPermissionGroups: string;
    _hidingMethods: HIDING_METHODS = HIDING_METHODS.HIDE;

    @Input() set ifAllow(_allowed: boolean) {
        this._allowed = true;
    }
    @Input() set ifAllowPermission(permission: string) {
        this._permission = permission;
    }
    @Input() set ifAllowPermissions(permissions: string) {
        this._permissions = permissions;
    }
    @Input() set ifAllowOneOfPermissions(permissions: string) {
        this._oneOfPermissions = permissions;
    }
    @Input() set ifAllowOneOfPermissionGroups(permissions: string) {
        this._oneOfPermissionGroups = permissions;
    }
    @Input() set ifAllowHidingMethods(method: HIDING_METHODS) {
        this._hidingMethods = method;
    }

    @Input() set ifDeny(_allowed: boolean) {
        this._allowed = false;
    }
    @Input() set ifDenyPermission(permission: string) {
        this._permission = permission;
    }
    @Input() set ifDenyPermissions(permissions: string) {
        this._permissions = permissions;
    }
    @Input() set ifDenyOneOfPermissions(permissions: string) {
        this._oneOfPermissions = permissions;
    }
    @Input() set ifDenyOneOfPermissionGroups(permissions: string) {
        this._oneOfPermissionGroups = permissions;
    }
    @Input() set ifDenyHidingMethods(method: HIDING_METHODS) {
        this._hidingMethods = method;
    }

    constructor(
        private el: ElementRef,
        private templateRef: TemplateRef<PermissionsDirective>,
        private viewContainer: ViewContainerRef,
        private accessService: AccessService,
    ) {}

    ngOnInit() {
        if (this.checkPermissions()) return this.viewContainer.createEmbeddedView(this.templateRef);

        switch (this._hidingMethods) {
            case HIDING_METHODS.HIDE:
                this.viewContainer.clear();
                break;
            case HIDING_METHODS.DISABLE:
                this.el.nativeElement.disabled = true;
                break;
        }
    }

    private checkPermissions() {
        if (this._permission && this.accessService.isAllowedPermission(this._permission)) return this._allowed;

        if (
            this._permissions &&
            every(this._permissions.split(/\s*,\s*/g), permission => this.accessService.isAllowedPermission(permission))
        )
            return this._allowed;

        if (
            this._oneOfPermissions &&
            this.accessService.isAllowedOneOfPermissions(this._oneOfPermissions.split(/\s*,\s*/g))
        )
            return this._allowed;

        if (this._oneOfPermissionGroups) {
            const groups = this._oneOfPermissionGroups.split(/\s*\|\s*/g);
            for (const index in groups) {
                if (this.accessService.isAllowedPermissions(groups[index].split(/\s*,\s*/g))) return this._allowed;
            }
            return !this._allowed;
        }

        return !this._allowed;
    }
}
