import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ViewSettings } from '../../api/resources/view-settings/view-settings.interfase';

@Injectable({
    providedIn: 'root',
})
export class ViewSettingsSharingService {
    ViewSettings: ViewSettings;
    behaviorSubject = new BehaviorSubject<ViewSettings>(undefined);
    currentSettings = this.behaviorSubject.asObservable();

    setSettings(viewSettings: ViewSettings) {
        this.behaviorSubject.next(viewSettings);
    }
}
