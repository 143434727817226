import { Injectable } from '@angular/core';
import { BehaviorSubject, MonoTypeOperatorFunction, pipe } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { SidebarMenuService } from './sidebar-menu.service';

@Injectable({
    providedIn: 'root',
})
export class AjaxLoaderService {
    hasFullWidth: boolean;
    private loadersList: string[] = [];
    changeState$ = new BehaviorSubject(false);

    constructor(private readonly sidebarMenuService: SidebarMenuService) {}

    start(id: string) {
        this.loadersList.push(id);
        this.changeState$.next(!!this.loadersList.length);
    }

    stop(id: string, delay = 0): void {
        setTimeout(() => {
            if (this.loadersList.indexOf(id) !== -1) {
                this.loadersList.splice(this.loadersList.indexOf(id), 1);
                this.changeState$.next(!!this.loadersList.length);
            }
        }, delay);
    }

    operate<T>(hasFullWidth = false): MonoTypeOperatorFunction<T> {
        this.hasFullWidth = hasFullWidth || !this.sidebarMenuService.isSidebarClosed;
        const id = uuidV4();
        this.start(id);
        return pipe(
            finalize(() => {
                this.stop(id);
            }),
        );
    }

    isFullWidth(): boolean {
        return this.hasFullWidth;
    }
}
