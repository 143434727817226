<button
    type="button"
    class="menu"
    (click)="showSidebar()"
    [class.closed]="isClosed"
    [attr.aria-expanded]="!isClosed"
    [attr.aria-label]="isClosed ? 'Open sidebar' : 'Close sidebar'"
    aria-live="polite">
    <i class="fa fa-bars menu__icon" *ngIf="isClosed; else closeIcon"></i>
    <ng-template #closeIcon>
        <i class="fa fa-close menu__icon"></i>
    </ng-template>
</button>
