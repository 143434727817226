import { Component, OnInit } from '@angular/core';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';

import { AjaxLoaderService } from '../../../services/ajax-loader.service';

@Component({
    selector: 'app-ajax-loader',
    templateUrl: './ajax-loader.component.html',
    styleUrls: ['./ajax-loader.component.scss'],
})
export class AjaxLoaderComponent implements OnInit {
    showedLoader = true;

    progressRef: NgProgressRef;
    constructor(public readonly ajaxLoaderService: AjaxLoaderService, private readonly ngProgress: NgProgress) {}

    ngOnInit(): void {
        this.progressRef = this.ngProgress.ref('app-ajax-progress');

        this.ajaxLoaderService.changeState$.subscribe(state => {
            if (state) this.start(state);
            else this.stop(state);
        });
    }

    start(state: boolean): void {
        this.showedLoader = state;
        if (this.progressRef.isStarted) {
            this.progressRef.complete();
        }
        this.progressRef.start();
    }

    stop(state: boolean): void {
        this.progressRef.complete();
        setTimeout(() => {
            this.showedLoader = state;
        }, 600);
    }
}
