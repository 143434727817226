import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, from, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { AccessService } from '../../../access/services/access.service';
import { PASSWORD_EXPIRY } from '../../../main/helper/constants/local-storage.constant';
import { SCOPE } from '../../../main/helper/constants/permissions.constants';
import { TokenEncrypterHelper } from '../../../main/helper/token-encrypter.helper';
import { UserScope } from '../../scope-services/user-scope.service';
import { AccessData } from '../auth/auth.interface';
import { Company } from '../company/company.interface';
import { MetricsService } from '../metrics/metrics.service';
import { Resource } from '../resource';
import { Search } from '../search.interface';
import {
    AcceptInvitationResponse,
    CreateUser,
    RecoveryPassword,
    RestorePassword,
    SignInPayload,
    SignInResponse,
    TfaSignIn,
    UpdatePassword,
    User,
    USER_STATUS,
} from './user.interface';

const ROUTE = {
    INVITE: 'user-auth/invite/:invite',
    INVITE_CHECK: 'user-auth/invite/:invite/check',
    USER_SELF: '/users/self',
    USER_SELF_EMAIL: '/users/self/email',
    USER_SELF_PASSWORD: '/users/self/password',
    AUTH: '/auth',
    SIGN_IN: '/auth',
    TFA_SIGN_IN: '/auth/tfa-sign-in',
    SEARCH: '/users',
    CREATE: '/users',
    DELETE_ROLES: '/users/:id/roles',
    PAUSE_ALL: '/users/:id/tfa-tokens/pause',
    BRAND_PAUSE_ALL: '/scope/brand/users/:id/tfa-tokens/pause',
    GLOBAL_PAUSE_ALL: '/scope/global/users/:id/tfa-tokens/pause',
    ACCEPT_INVITATION: '/users/invitation/accept',
    BRAND_ACCEPT_INVITATION: '/scope/brand/users/invitation/accept',
    GLOBAL_ACCEPT_INVITATION: '/scope/global/users/invitation/accept',
    MODIFY: '/users/:id',
    PATCH: '/users/:id',
    DEACTIVATE: '/users/:id/deactivate',
    ACTIVATE: '/users/:id/activate',
    DELETE: '/users/:id',
    RECOVERY_PASSWORD: '/users/self/recovery-password',
};

export interface AcceptInvitationPayload {
    password: string;
    password_confirmed: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserService extends Resource implements UserScope {
    constructor(
        protected httpClient: HttpClient,
        private accessService: AccessService,
        private metricsService: MetricsService,
    ) {
        super(httpClient);
    }

    private companySubject = new BehaviorSubject<Company>({} as Company);
    private userSubject = new BehaviorSubject<User>(undefined);
    companyId = new BehaviorSubject('');

    checkUserInvite(invite: string): Observable<User> {
        return this.http.post<User>(this.getLink(ROUTE.INVITE_CHECK, { invite }, 'front'), {});
    }

    createInvite(body: Company, invite: string, token: string) {
        const headers: HttpHeaders = new HttpHeaders().append('x-captcha-response', token);
        return this.http.post<any>(this.getLink(ROUTE.INVITE, { invite }, 'front'), body, { headers });
    }

    update(body: Partial<User>): Observable<User> {
        return this.http.put<User>(this.getLink(ROUTE.USER_SELF), body);
    }

    patchUser(userId: string, scope: SCOPE, body: Partial<User['meta']>): Observable<User> {
        const prefix = scope === SCOPE.BRAND ? '/scope/brand' : scope === SCOPE.GLOBAL ? '/scope/global' : '';
        return this.http.patch<User>(this.getLink(prefix + ROUTE.PATCH, { id: userId }), body);
    }

    getCurrent(): Observable<User> {
        return this.http.get<User>(this.getLink(ROUTE.USER_SELF));
    }

    signIn(payload: SignInPayload): Observable<SignInResponse> {
        return this.http.post<SignInResponse>(this.getLink(ROUTE.SIGN_IN), {
            username: payload.email.toLowerCase(),
            password: payload.password,
        });
    }

    tfaSignIn(payload: TfaSignIn): Observable<SignInResponse> {
        const accessToken = this.accessService.getToken();
        let headers = new HttpHeaders().append('x-tfa-code', payload.tfa_code);
        if (accessToken) headers = headers.append('x-access-token', accessToken);
        return this.http.post<SignInResponse>(this.getLink(ROUTE.TFA_SIGN_IN), null, { headers });
    }

    logOut() {
        const tempAccessData = AccessService.getTempAccessData();
        const primaryToken = this.accessService.getToken();
        const logOuts = [this.logOutByToken(primaryToken)];
        logOuts.push(
            ...Object.values(tempAccessData).map((data: AccessData) => {
                return data ? this.logOutByToken(data.token) : null;
            }),
        );
        this.accessService.clearAccessData();
        this.metricsService.clearLocalData();
        localStorage.removeItem(PASSWORD_EXPIRY.ALERT_SHOWED);
        return forkJoin(logOuts);
    }

    logOutByToken(token: string): Observable<any> {
        return from(TokenEncrypterHelper.decryptToken(token)).pipe(
            switchMap(decryptedToken => {
                const headers = { 'x-access-token': decryptedToken };
                return this.http.delete(this.getLink(ROUTE.AUTH), { headers });
            }),
        );
    }

    get company$(): Observable<Company> {
        return this.companySubject.asObservable().pipe(filter(company => !!company));
    }

    get user$(): Observable<User> {
        return this.userSubject.asObservable().pipe(filter(user => !!user));
    }

    setUser(user: User) {
        this.userSubject.next(user);
    }

    addCompany(data: Company) {
        this.companySubject.next(data);
        if (data && data.company_account_id) this.companyId.next(data.company_account_id);
    }

    updateEmail(email: string, currentPassword: string): Observable<null> {
        return this.http.post<null>(this.getLink(ROUTE.USER_SELF_EMAIL), { email, current_password: currentPassword });
    }

    updatePassword(payload: UpdatePassword, isTokenNeeded = false): Observable<void> {
        let headers: HttpHeaders;
        const accessToken: string = this.accessService.getToken();
        if (isTokenNeeded) {
            headers = new HttpHeaders().append('x-access-token', accessToken);
        }
        return this.http.post<void>(this.getLink(ROUTE.USER_SELF_PASSWORD), payload, accessToken ? { headers } : {});
    }

    getUpdatePasswordUriInfo(): { method: string; uri: string } {
        return {
            uri: this.getLink(ROUTE.USER_SELF_PASSWORD),
            method: 'POST',
        };
    }

    search(query: Record<string, unknown> = {}): Observable<Search<User[]>> {
        const params = new HttpParams().set('isSearch', 'true');
        return this.http.get<Search<User[]>>(this.getLink(ROUTE.SEARCH, query), { params });
    }

    create(user: CreateUser): Observable<{ user_id: string }> {
        return this.http.post<{ user_id: string }>(this.getLink(ROUTE.CREATE), user);
    }

    /**
     * TODO: make a strategy as we have for role service and etc
     */
    pauseTfaTokens(id: string): Observable<any> {
        return this.http.post<string>(this.getLink(ROUTE.PAUSE_ALL, { id }), {});
    }

    brandPauseTfaTokens(id: string): Observable<any> {
        return this.http.post<string>(this.getLink(ROUTE.BRAND_PAUSE_ALL, { id }), {});
    }

    globalPauseTfaTokens(id: string): Observable<any> {
        return this.http.post<string>(this.getLink(ROUTE.GLOBAL_PAUSE_ALL, { id }), {});
    }

    acceptInvitation(
        token: string,
        scope: SCOPE,
        body?: AcceptInvitationPayload,
    ): Observable<AcceptInvitationResponse> {
        const { uri, method } = this.getAcceptInvitationUriInfo(scope);

        return this.http.request<AcceptInvitationResponse>(method, uri, {
            body,
            headers: { 'x-access-token': token, anonymous: '' },
        });
    }

    getAcceptInvitationUriInfo(scope: SCOPE): { uri: string; method: string } {
        let uri = ROUTE.ACCEPT_INVITATION;

        if (scope === SCOPE.GLOBAL) uri = ROUTE.GLOBAL_ACCEPT_INVITATION;

        if (scope === SCOPE.BRAND) uri = ROUTE.BRAND_ACCEPT_INVITATION;

        return {
            uri: this.getLink(uri),
            method: 'POST',
        };
    }

    modify(userId: string, modify: { status: USER_STATUS }): Observable<{ status: USER_STATUS }> {
        return this.http.post<{ status: USER_STATUS }>(this.getLink(ROUTE.MODIFY, { id: userId }), modify);
    }

    deactivate(id: string): Observable<null> {
        return this.http.put<null>(this.getLink(ROUTE.DEACTIVATE, { id }), {});
    }

    activate(id: string): Observable<null> {
        return this.http.put<null>(this.getLink(ROUTE.ACTIVATE, { id }), {});
    }

    archive(id: string): Observable<void> {
        return this.http.delete<void>(this.getLink(ROUTE.DELETE, { id }), {});
    }

    recoveryPassword(payload: RecoveryPassword): Observable<void> {
        return this.http.post<void>(this.getLink(ROUTE.RECOVERY_PASSWORD), payload);
    }

    restorePassword(token: string, payload?: RestorePassword): Observable<void> {
        const { method, uri } = this.getRestorePasswordUriInfo();
        return this.http.request<void>(method, uri, {
            body: payload,
            headers: { 'x-access-token': token, anonymous: '' },
        });
    }

    getRestorePasswordUriInfo(): { method: string; uri: string } {
        return {
            uri: this.getLink(ROUTE.RECOVERY_PASSWORD),
            method: 'PUT',
        };
    }
}
