import { Component, OnInit } from '@angular/core';
import { get } from 'lodash';

import { AccessService } from '../../../../access/services/access.service';
import { Bulletin } from '../../../../api/resources/bulletin/bulletin.interface';
import { BulletinService } from '../../../../api/resources/bulletin/bulletin.service';

@Component({
    selector: 'app-bulletin',
    templateUrl: './bulletin.component.html',
    styleUrls: ['./bulletin.component.scss'],
})
export class BulletinComponent implements OnInit {
    bulletins;

    constructor(private accessService: AccessService, private bulletinServer: BulletinService) {}

    ngOnInit(): void {
        if (this.accessService.isAllowedPermission('bulletins:search'))
            this.bulletinServer.getAll().subscribe((data: Bulletin[]) => {
                this.bulletins = get(data, 'bulletins');
            });
    }

    deleteBulletin(id: string): void {
        this.bulletinServer.remove(id).subscribe(() => {});
    }
}
