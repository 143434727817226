import { indexOf, isNil, isObject } from 'lodash';
import * as QueryString from 'query-string';
import { ParsedQuery } from 'query-string';

export const LOCALHOST = 'localhost';
export const PAYDOCK_ALIAS = 'paydock.com';

export class Uri {
    static updateQueryParam(uri: string, key: string, val: string | object): string {
        return uri
            .replace(
                new RegExp('([?&]' + key + '(?=[=&#]|$)[^#&]*|(?=#|$))'),
                '&' + key + '=' + encodeURIComponent(Uri.formValue(val)),
            )
            .replace(/^([^?&]+)&/, '$1?');
    }

    static updateQueryParams(originUri: string, params: any): string {
        let uri = String(originUri);
        for (const key in params) {
            if (!isNil(params[key])) {
                uri = this.updateQueryParam(uri, key, params[key]);
            }
        }
        return uri;
    }

    static isQueryString(queryString: string): boolean {
        return indexOf(queryString, '?') === 0;
    }

    static parseQueryString(queryString: string): ParsedQuery<string> {
        return QueryString.parse(queryString);
    }

    public static extractAppAlias(host: string, countSubDomain: number): string {
        const split = host.split('.');
        if (host.indexOf(LOCALHOST) !== -1) return LOCALHOST;

        split.splice(0, countSubDomain);
        return split.join('.');
    }

    public static isOriginalHost(host: string, countSubDomain: number): boolean {
        return Uri.extractAppAlias(host, countSubDomain) === PAYDOCK_ALIAS;
    }

    public static formValue(val: string | object): string {
        return isObject(val) ? val.toString().replace(/ /g, '') : val;
    }
}
