import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AccessService } from './services/access.service';

export function initializeApp(accessService: AccessService) {
    return (): Promise<any> => {
        return accessService.init();
    };
}

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        AccessService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AccessService],
            multi: true,
        },
    ],
})
export class AccessModule {}
