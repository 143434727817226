import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccessService } from '../../../access/services/access.service';
import { Company } from '../../../api/resources/company/company.interface';
import { CompanyService } from '../../../api/resources/company/company.service';
import { UserService } from '../../../api/resources/user/user.servise';
import { AutoDestroy } from '../../decorators/auto-destroy.decorator';
import { SCOPE } from '../../helper/constants/permissions.constants';
import { SidebarMenuService } from '../../services/sidebar-menu.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    isSidebarShowed: boolean;
    @AutoDestroy private readonly unsubscribe$ = new Subject<void>();

    constructor(
        private readonly sidebarMenuService: SidebarMenuService,
        private readonly accessService: AccessService,
        private readonly companyService: CompanyService,
        private readonly userService: UserService,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.isSidebarShowed = !this.sidebarMenuService.isSidebarClosed;
        const scope = this.accessService.identifyScope();
        if (scope === SCOPE.COMPANY && this.accessService.isAllowedPermission('companies:get'))
            this.companyService
                .getCurrent()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((company: Company) => {
                    this.userService.addCompany(company);
                });
        else this.userService.addCompany({ company_account_id: this.accessService.getCompanyAccountId() });
    }

    onToggle(isShowed: boolean) {
        this.isSidebarShowed = isShowed;
        this.sidebarMenuService.setSidebarState = isShowed;
        this.changeDetectorRef.detectChanges();
    }
}
