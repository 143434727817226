import { inject, Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { APP_CONFIG } from 'src/modules/main/providers/app.config';

import { AnalyticsAgent } from './analytics-agent';
import { CookieConsentService } from './cookie-consent/cookie-consent.service';
import { ITracker } from './cookie-consent/tracker.interface';
import { CookieCategory, TrackerID, UserConsentValue } from './cookie-consent/types';

/**
 * New relic documentation available at:
 *
 * @see https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/using-browser-apis/#agent-control-api
 * @see https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/using-browser-apis/#api-errors
 * @see https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/using-browser-apis/#api-traces
 * @see https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/using-browser-apis/#spa-api
 */
@Injectable({
    providedIn: 'root',
})
export class AnalyticsService implements OnDestroy, ITracker {
    public readonly id: TrackerID = 'nreum';
    public readonly category: CookieCategory = 'analytics';

    private readonly available: boolean;
    private readonly config: Record<string, unknown>;
    private readonly userConsentSubscription: Subscription | undefined;

    private agent: AnalyticsAgent | undefined;

    constructor(private cookieConsentService: CookieConsentService) {
        const appConfig = inject(APP_CONFIG);

        this.config = appConfig.newrelic.browserAgent.options;
        this.available = appConfig.newrelic.browserAgent.enabled;

        if (this.available) {
            this.agent = this.createAgent();
            this.userConsentSubscription = this.cookieConsentService.userConsent$
                .pipe(debounceTime(125))
                .subscribe(value => {
                    this.onUserConsent(value);
                });
        }
    }

    ngOnDestroy(): void {
        this.userConsentSubscription?.unsubscribe();
    }

    trackAction(action, attributes: Record<string, unknown>) {
        this.delegate(() => this.agent.addPageAction(action, attributes));
    }

    private createAgent() {
        this.agent = new AnalyticsAgent(this.config, this.config['loaderConfig']['agentID']);

        return this.agent;
    }

    private delegate(fn: () => void) {
        if (this.available && this.agent) {
            fn();
        }
    }

    private restart() {
        this.agent.enableFeatures();
    }

    private start() {
        this.createAgent().enableFeatures();
    }

    private stop() {
        this.agent.disableNonEssentialFeatures();
    }

    private onUserConsent(current: UserConsentValue) {
        if (!this.available) {
            return;
        }

        const activeCategory = current[this.category] === true;
        const activeService = current.services[this.category].includes(this.id);
        const shouldStart = activeCategory && activeService;

        if (shouldStart) {
            if (!this.agent) {
                return this.start();
            }

            return this.restart();
        }

        if (this.agent) {
            return this.stop();
        }
    }
}
