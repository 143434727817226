import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';

import { AccessService } from './access.service';

@Injectable({
    providedIn: 'root',
})
export class SubAccountsSharedService {
    get isSubAccount(): boolean {
        return !isEmpty(AccessService.getTempAccessData());
    }
}
