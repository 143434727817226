export const ACCESS_STORAGE_KEY = {
    TEMP_ACCESS_DATA: 'temp_access_data',
    ACCESS_DATA: 'access_data',
    SHARED: 'shared',
    SHARED_COMPANIES: 'shared_companies',
    USER: 'user',
    DRAFT_REPORT_ID: 'draft_report_id',
};

export const PASSWORD_EXPIRY = {
    PASSWORD_EXPIRED: 'password_expired',
    PASSWORD_EXPIRES_AT: 'password_expires_at',
    ALERT_SHOWED: 'alert_showed',
};

export const METRICS_STORAGE_KEY = {
    GLOBAL_RESPONSE_TIME: 'metric_global_response_time',
};
