import { Injectable } from '@angular/core';

import { Style } from '../../api/resources/view-settings/view-settings.interfase';

@Injectable({ providedIn: 'root' })
export class ThemeService {
    setStyle(vars: Style): void {
        Object.keys(vars).forEach((key: string) => {
            document.documentElement.style.setProperty(`--${key}`, vars[key]);
        });
    }
}
