import { Injectable } from '@angular/core';
import { get, isEmpty, omit } from 'lodash';
import { Subject } from 'rxjs';

import {
    AccessData,
    AuthInterface,
    FeatureOptionsList,
    TempAccessData,
    UpdateAccess,
} from '../../api/resources/auth/auth.interface';
import { ACCESS_STORAGE_KEY, PASSWORD_EXPIRY } from '../../main/helper/constants/local-storage.constant';
import { SCOPE } from '../../main/helper/constants/permissions.constants';
import { TokenEncrypterHelper } from '../../main/helper/token-encrypter.helper';

@Injectable()
export class AccessService {
    private redirectWindowSubject = new Subject<void>();
    public redirectWindow$ = this.redirectWindowSubject.asObservable();
    private _currentPassword: string;
    private _currentToken: string | null = null;

    async init(): Promise<void> {
        const accessData = AccessService.getAccessData();
        if (!isEmpty(accessData)) await this.setCurrentToken(accessData.token);
    }

    get currentPassword(): string {
        return this._currentPassword;
    }

    set currentPassword(password: string) {
        this._currentPassword = password;
    }

    private get currentToken(): string {
        return this._currentToken;
    }

    private async setCurrentToken(token: string): Promise<void> {
        this._currentToken = await TokenEncrypterHelper.decryptToken(token);
    }

    showRedirectWindow(): void {
        this.redirectWindowSubject.next();
    }

    public static isAllowedPermission(permission: string): boolean {
        const permissions = this.getPermissions();
        return permissions && permissions.indexOf(permission) !== -1;
    }

    public static isAllowedPermissions(permissions: string[]): boolean {
        return permissions.every(permission => this.isAllowedPermission(permission));
    }

    public static isAllowedOneOfPermissions(permissions: string[]): boolean {
        return permissions.some(permission => this.isAllowedPermission(permission));
    }

    public static isAllowedOnePermissionGroup(permissions: string): boolean {
        if (permissions.length) {
            const groups = permissions.split(/\s*\|\s*/g);
            for (const groupItem of groups) {
                if (this.isAllowedPermissions(groupItem.split(/\s*,\s*/g))) return true;
            }
            return false;
        }

        return false;
    }

    public static getPermissions(): string[] {
        try {
            return get(AccessService.getAccessData(), 'permissions', []);
        } catch {
            return [];
        }
    }

    getAvailableFeatures(): FeatureOptionsList | undefined {
        const accessData = AccessService.getAccessData();
        if (isEmpty(accessData)) return;
        return get(accessData, 'avalable_features_options') as FeatureOptionsList;
    }

    isPasswordExpired(): boolean {
        const accessData = AccessService.getAccessData();
        if (isEmpty(accessData)) return;
        return get(accessData, PASSWORD_EXPIRY.PASSWORD_EXPIRED, false);
    }

    getPasswordExpiresAt(): string {
        const accessData = AccessService.getAccessData();
        if (isEmpty(accessData)) return;
        return get(accessData, PASSWORD_EXPIRY.PASSWORD_EXPIRES_AT, '');
    }

    hasShowedExpiryPassword(): boolean {
        return localStorage.getItem(PASSWORD_EXPIRY.ALERT_SHOWED) === 'true';
    }

    updatePasswordExpiryAlertFlag(state: boolean): void {
        localStorage.setItem(PASSWORD_EXPIRY.ALERT_SHOWED, String(state));
    }

    async switchToSubAccount(subAccount: AuthInterface, scope: SCOPE): Promise<void> {
        const currentAccessData = AccessService.getAccessData();
        if (isEmpty(currentAccessData)) return;

        const tempAccessData = { ...AccessService.getTempAccessData() };
        tempAccessData[scope] = { ...currentAccessData };
        this.updateTempAccessData(tempAccessData);
        await this.updateAccess({
            token: subAccount.token,
            permissions: subAccount.permissions,
            user_id: subAccount.user_id,
            tfa_required: subAccount.tfa_required,
            avalable_features_options: subAccount.avalable_features_options,
            sub_account_name: scope === SCOPE.GLOBAL ? subAccount.brand_label : subAccount.company_account_name,
        });
    }

    switchBackSubAccount(): object | null {
        const tempAccessData = AccessService.getTempAccessData();
        if (isEmpty(tempAccessData)) return null;

        const scope = tempAccessData.global && !tempAccessData.brand ? SCOPE.GLOBAL : SCOPE.BRAND;
        const targetAccessData = tempAccessData[scope];
        const newTempAccessData = omit(tempAccessData, [scope]);
        isEmpty(newTempAccessData) ? this.clearTempAccessData() : this.updateTempAccessData(newTempAccessData);

        const oldAccessData = AccessService.getAccessData();
        const returnData = {};
        returnData[scope] = oldAccessData;

        this.updateAccess({
            token: targetAccessData.token,
            permissions: targetAccessData.permissions,
            user_id: targetAccessData.user_id,
            tfa_required: targetAccessData.tfa_required,
            avalable_features_options: targetAccessData.avalable_features_options,
            sub_account_name: targetAccessData.sub_account_name,
        });
        return returnData;
    }

    async updateAccess(options: UpdateAccess): Promise<void> {
        const encryptedToken = await TokenEncrypterHelper.encryptToken(options.token);
        await this.setCurrentToken(options.token);
        localStorage.setItem(ACCESS_STORAGE_KEY.ACCESS_DATA, JSON.stringify({ ...options, token: encryptedToken }));
    }

    updateTempAccessData(tempAccessData: TempAccessData): void {
        localStorage.setItem(ACCESS_STORAGE_KEY.TEMP_ACCESS_DATA, JSON.stringify(tempAccessData));
    }

    deleteAccess(): void {
        if (this.isAccessExist()) {
            Object.values(ACCESS_STORAGE_KEY).forEach(key => localStorage.removeItem(key));
            localStorage.removeItem(PASSWORD_EXPIRY.ALERT_SHOWED);
        }
    }

    clearTempAccessData(): void {
        localStorage.removeItem(ACCESS_STORAGE_KEY.TEMP_ACCESS_DATA);
    }

    clearAccessData(): void {
        localStorage.removeItem(ACCESS_STORAGE_KEY.ACCESS_DATA);
        localStorage.removeItem(ACCESS_STORAGE_KEY.DRAFT_REPORT_ID);
        this.clearTempAccessData();
    }

    getPermissions() {
        return this.isAccessExist() ? AccessService.getAccessData().permissions || [] : [];
    }

    getUser(): string | null {
        return this.isAccessExist() ? AccessService.getAccessData().user_id : null;
    }

    getDestinationUrl(): string | null {
        return AccessService.getAccessData().destination_url || null;
    }

    isAccessExist(): boolean {
        if (isEmpty(AccessService.getAccessData())) return false;

        const payload = this.decodePayload(this.currentToken);
        const currentDate = Math.round(new Date().getTime() / 1000);
        return payload && payload.exp > currentDate;
    }

    isTfaRequired(): boolean {
        const accessData = AccessService.getAccessData();
        return accessData.tfa_required;
    }

    static getAccessData(): AccessData {
        return JSON.parse(localStorage.getItem(ACCESS_STORAGE_KEY.ACCESS_DATA) || '{}');
    }

    static getTempAccessData(): TempAccessData {
        return JSON.parse(localStorage.getItem(ACCESS_STORAGE_KEY.TEMP_ACCESS_DATA) || '{}');
    }

    isSubAccountAccess(): boolean {
        return !isEmpty(AccessService.getTempAccessData());
    }

    getToken(): string | null {
        if (!this.isAccessExist()) return null;
        return this.currentToken;
    }

    getCompanyAccountId(): string | null {
        return this.isAccessExist() ? AccessService.getAccessData().company_account_id : null;
    }

    getPreviousLogin(): string | null {
        const accessData = AccessService.getAccessData();
        return accessData.previous_login;
    }

    isAllowedPermission(permission: string): boolean {
        return this.getPermissions().indexOf(permission) !== -1;
    }

    isAllowedPermissions(permissions: string[]): boolean {
        return permissions.every(permission => this.isAllowedPermission(permission));
    }

    isAllowedOneOfPermissions(permissions: string[]): boolean {
        return permissions.some(permission => this.isAllowedPermission(permission));
    }

    isAllowedOneOfPermissionsGroup(permissionsGroups: string[][]): boolean {
        return permissionsGroups.some(permissions => this.isAllowedPermissions(permissions));
    }

    identifyScope(): SCOPE {
        const permissions = this.getPermissions();
        for (const permission of permissions) {
            if (permission.includes(SCOPE.BRAND) && !permission.includes(SCOPE.GLOBAL)) return SCOPE.BRAND;

            if (permission.includes(SCOPE.GLOBAL)) return SCOPE.GLOBAL;
        }
        return SCOPE.COMPANY;
    }

    private decodePayload(token: string): JwtPayload {
        if (!token) return null;
        const [, payload] = token.split('.');
        return JSON.parse(atob(payload));
    }
}

interface JwtPayload {
    id: string;
    exp: number;
    iat: number;
    meta?: Record<string, unknown>;
}
