import { KeyValue } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiModule } from '../../api.module';
import { Customer } from '../customer/customer.interface';
import { Resource } from '../resource';
import { Search } from '../search.interface';
import { Balance, Charge } from './charge.interface';
import { ChargeStatistics } from './charge-statistics.interface';

const ROUTE = {
    CHARGES: '/charges',
    CHARGE_STATS: '/charges/statistics',
    BRAND_CHARGES: '/scope/brand/charges',
    BRAND_CHARGES_WITH_ID: '/scope/brand/charges/:id',
    CHARGES_WITH_ID: '/charges/:id',
    REFUND: '/charges/:id/refunds',
    REFUND_MULTICAPTURE: '/charges/:id/transactions/:transaction_id/refunds',
    REFUND_ONLY: '/charges/refunds',
    DECLINE: '/charges/:id/decline',
    PROCESS: '/charges/:id/process',
    CHARGES_REFUND: '/charges/:id/refunds',
    CAPTURE: '/charges/:id/capture',
    DYNAMIC_PRICING: '/charges/dynamic-pricing',
    UPDATE_TRANSACTION_STATUS: '/charges/:id/transactions/:transaction_id',
    FRAUD_CHARGES: '/charges/fraud',
    ATTACH_FRAUD: '/charges/:id/fraud/attach',
    VERIFICATION: '/charges/verification',
    BALANCE: '/charges/balance',
    CAPTURE_TRANSACTION_BY_ID: '/charges/:id/transactions/:transaction_id/capture',
};
export enum TIME_DIMENSION {
    DAY = 'day',
    HOUR = 'hour',
}
interface StatisticsConditions {
    dateFrom: string;
    dateTo: string;
    gatewayType?: string;
    gatewayName?: string;
    currency?: string;
    timeDimension?: TIME_DIMENSION;
}

interface DynamicPricing {
    amount: number;
    foreign_currency: string;
    gateway_id: string;
}

export interface DynamicPricingResponse {
    external_id: string;
    foreign_amount: number;
    foreign_currency: string;
    settlement_currency: string;
    exchange_rates: string;
    expiration_time: string;
}

export interface UpdateTransactionStatus {
    status: string;
    external_id: string;
    charge_status?: string;
    error_code?: string;
}

export interface RefundBody {
    amount: string;
    initialization_source?: string;
}

export interface CancelBody {
    initialization_source?: string;
    cancel_amount?: string;
}

export enum CHARGE_ID_TYPE {
    FRAUD = 'fraud_charge_id',
    _3DS = '_3ds_charge_id',
}

export interface GetBalancePayload {
    customer: Partial<Customer>;
    terminal_id?: string;
}

@Injectable({
    providedIn: ApiModule,
})
export class ChargeService extends Resource {
    get(id: string): Observable<Charge> {
        return this.http.get<Charge>(this.getLink(ROUTE.CHARGES_WITH_ID, { id }));
    }

    brandGet(id: string): Observable<Charge> {
        return this.http.get<Charge>(this.getLink(ROUTE.BRAND_CHARGES_WITH_ID, { id }));
    }

    search(query: any): Observable<Search<Charge[]>> {
        const params = new HttpParams().set('isSearch', 'true');
        return this.http.get<Search<Charge[]>>(this.getLink(ROUTE.CHARGES, query), { params });
    }

    brandSearch(query: any): Observable<Search<Charge[]>> {
        const params = new HttpParams().set('isSearch', 'true');
        return this.http.get<Search<Charge[]>>(this.getLink(ROUTE.BRAND_CHARGES, query), { params });
    }

    save(charge: Charge, capture: boolean = true): Observable<Charge> {
        return this.http.post<Charge>(this.getLink(ROUTE.CHARGES, { capture }), charge);
    }

    saveFraud(charge: Charge): Observable<null> {
        return this.http.post<null>(this.getLink(ROUTE.FRAUD_CHARGES), charge);
    }

    archive(id: string): Observable<Charge> {
        return this.http.delete<Charge>(this.getLink(ROUTE.CHARGES_WITH_ID, { id }));
    }

    refund(id: string, body: RefundBody): Observable<Charge> {
        return this.http.post<Charge>(this.getLink(ROUTE.REFUND, { id }), body);
    }

    refundMulticapture(id: string, transaction_id: string, amount: string): Observable<Charge> {
        return this.http.post<Charge>(this.getLink(ROUTE.REFUND_MULTICAPTURE, { id, transaction_id }), { amount });
    }

    refundOnly(charge: Charge): Observable<Charge> {
        return this.http.post<Charge>(this.getLink(ROUTE.REFUND_ONLY), charge);
    }

    refundStatus(id: string): Observable<Charge> {
        return this.http.get<Charge>(this.getLink(ROUTE.REFUND, { id }));
    }

    decline(id: string): Observable<Charge> {
        return this.http.post<Charge>(this.getLink(ROUTE.DECLINE, { id }), {});
    }

    process(id: string): Observable<Charge> {
        return this.http.post<Charge>(this.getLink(ROUTE.PROCESS, { id }), {});
    }

    capture(id: string, amount: string): Observable<Charge> {
        return this.http.post<Charge>(this.getLink(ROUTE.CAPTURE, { id }), { amount });
    }

    verification(charge: Charge): Observable<Charge> {
        return this.http.post<Charge>(this.getLink(ROUTE.VERIFICATION), charge);
    }

    cancel(id: string, body?: CancelBody): Observable<Charge> {
        return this.http.request<Charge>('delete', this.getLink(ROUTE.CAPTURE, { id }), {
            ...(body && { body }),
        });
    }

    getStatistics(query: StatisticsConditions): Observable<{ statistics: ChargeStatistics[]; currencies: string[] }> {
        return this.http.get<{ statistics: ChargeStatistics[]; currencies: string[] }>(
            this.getLink(ROUTE.CHARGE_STATS, query),
        );
    }

    checkDynamicPricing(payload: DynamicPricing): Observable<DynamicPricingResponse> {
        return this.http.post<DynamicPricingResponse>(this.getLink(ROUTE.DYNAMIC_PRICING), payload);
    }

    update(id: string, transactionId: string, updateTransactionStatus: UpdateTransactionStatus): Observable<Charge> {
        return this.http.post<Charge>(
            this.getLink(ROUTE.UPDATE_TRANSACTION_STATUS, { id, transaction_id: transactionId }),
            updateTransactionStatus,
        );
    }

    attach(id: string, fraudId: string): Observable<Charge> {
        return this.http.post<Charge>(this.getLink(ROUTE.ATTACH_FRAUD, { id }), { fraud_charge_id: fraudId });
    }

    getAdditionalInformation(): KeyValue<CHARGE_ID_TYPE, string>[] {
        return [
            { key: CHARGE_ID_TYPE.FRAUD, value: 'Fraud charge ID' },
            { key: CHARGE_ID_TYPE._3DS, value: '3DS charge ID' },
        ];
    }

    getCardAcceptType(): KeyValue<string, string>[] {
        return [
            { key: 'normalPresentment', value: 'Normal Presentment' },
            { key: 'merchantInitiated', value: 'Merchant Initiated' },
            { key: 'electronicCashRegisterInterface', value: 'Electronic Cash Register Interface' },
            { key: 'customerTerminal', value: 'Customer Terminal' },
            { key: 'administrationTerminal', value: 'Administration Terminal' },
            { key: 'unattendedTerminal', value: 'Unattended Terminal' },
            { key: 'electronicPaymentTerminal', value: 'Electronic Payment Terminal' },
            { key: 'cardActivatedFuelDispenser', value: 'Card Activated Fuel Dispenser' },
            { key: 'travelTicketVendingMachine', value: 'Travel Ticket Vending Machine' },
            { key: 'generalVendingMachine', value: 'General Vending Machine' },
            { key: 'electronicCommerce', value: 'Electronic Commerce' },
        ];
    }

    getCardHolderAcceptMethods(): KeyValue<string, string>[] {
        return [
            { key: 'notSpecified', value: 'Not Specified' },
            { key: 'smartphone', value: 'Smartphone' },
            { key: 'tablet', value: 'Tablet' },
            { key: 'pc', value: 'PC' },
            { key: 'card', value: 'Card' },
            { key: 'other', value: 'Other' },
        ];
    }

    getBalance(payload: GetBalancePayload): Observable<Balance> {
        return this.http.post<Balance>(this.getLink(ROUTE.BALANCE), payload);
    }

    cancelTransaction(id: string, transaction_id: string): Observable<void> {
        return this.http.delete<void>(this.getLink(ROUTE.CAPTURE_TRANSACTION_BY_ID, { id, transaction_id }));
    }
}
