import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get, has } from 'lodash';
import { map } from 'rxjs/operators';

@Injectable()
export class TransformResponseInterceptor implements HttpInterceptor {
    protected static modifyResponse(event, method, isSearch) {
        if (method !== 'GET' || !isSearch) return get(event, 'body.resource.data');

        return {
            data: get(event, 'body.resource.data'),
            info: {
                type: get(event, 'body.resource.type'),
                limit: get(event, 'body.resource.limit'),
                count: get(event, 'body.resource.count'),
                skip: get(event, 'body.resource.skip'),
                groups: get(event, 'body.resource.resource_groups'),
                features: get(event, 'body.resource.features'),
                possible_custom_transaction_fields: get(event, 'body.resource.possible_custom_transaction_fields'),
                possible_transaction_fields: get(event, 'body.resource.possible_transaction_fields'),
                possible_transaction_card_acceptor_fields: get(
                    event,
                    'body.resource.possible_transaction_card_acceptor_fields',
                ),
                possible_fee_fields: get(event, 'body.resource.possible_fee_fields'),
                possible_fields: get(event, 'body.resource.possible_fields'),
                possible_3ds_fields: get(event, 'body.resource.possible_3ds_fields'),
                possible_entities: get(event, 'body.resource.possible_entities'),
                query_token: get(event, 'body.resource.query_token'),
            },
        };
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const newReq = req.clone({
            params: req.params.delete('isSearch'),
        });
        return next.handle(newReq).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && has(event, 'body.resource.data')) {
                    return event.clone({
                        body: TransformResponseInterceptor.modifyResponse(
                            event,
                            req.method,
                            req.params.get('isSearch'),
                        ),
                    });
                } else {
                    return event;
                }
            }),
        );
    }
}
