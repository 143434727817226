import { Injectable } from '@angular/core';
import { isObject, isString } from 'lodash';
import { Subject } from 'rxjs';

import { MessageParseService } from './message-parse-service';

export const MESSAGE_TYPE = {
    WARNING: 'warn',
    INFO: 'info',
    SUCCESS: 'success',
    ERROR: 'error',
};

export interface Toastr {
    id: number;
    type: string;
    delay: number;
    message?: string | object;
    collectionDetails?: string[];
    timerId?: number;
}

const DELAY = 7000;

@Injectable({
    providedIn: 'root',
})
export class MessagePopupService {
    toastrs = new Subject<Toastr>();

    constructor(private messageParseService: MessageParseService) {}

    showMessage(message, type: string, delay: number = DELAY): void {
        if (isString(message))
            this.toastrs.next({
                message: this.messageParseService.getMessage(message),
                type,
                delay,
                id: Math.floor(Math.random() * Date.now()),
            });

        if (isObject(message))
            this.toastrs.next({
                collectionDetails: this.messageParseService.getCollectionDetails(message),
                type,
                delay,
                id: Math.floor(Math.random() * Date.now()),
            });
    }

    clear(timerId: number): void {
        clearTimeout(timerId);
    }
}
