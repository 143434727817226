import { AfterContentInit, Component, ElementRef, Input } from '@angular/core';

@Component({
    selector: 'form-error',
    templateUrl: './form-error.component.html',
    styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent implements AfterContentInit {
    textInput: string;

    @Input() type: string;

    constructor(private elementRef: ElementRef) {}

    ngAfterContentInit(): void {
        this.textInput = this.elementRef.nativeElement.textContent;
    }
}
