import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AccessService } from '../../access/services/access.service';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
    constructor(private readonly accessService: AccessService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authToken = this.accessService.getToken();

        // Custom internal header to identify post-processing catch inside of interceptor
        let newReq = req;
        const isAnonymous = newReq.headers.has('anonymous');
        if (isAnonymous) {
            newReq = newReq.clone({
                headers: newReq.headers.delete('anonymous'),
            });
        }
        if (authToken && !newReq.headers.get('x-access-token')) {
            newReq = newReq.clone({
                headers: newReq.headers.set('x-access-token', authToken),
            });
        }

        return next.handle(newReq).pipe(
            catchError((error: any) => {
                if (
                    error instanceof HttpErrorResponse &&
                    !isAnonymous &&
                    get(error, 'status') === 403 &&
                    ['token_expired', 'token_inactive', 'token_broken', 'paused'].includes(
                        get(error, 'error.error.code'),
                    )
                ) {
                    const destinationUrl = this.accessService.getDestinationUrl();
                    this.accessService.deleteAccess();
                    destinationUrl ? (window.location.href = destinationUrl) : location.reload();

                    return of(error);
                }
                throw error;
            }) as any,
        );
    }
}
