import { KeyValue } from '@angular/common';

export const ROUTES_PERMISSIONS_LIST: KeyValue<string, string[][]>[] = [
    {
        key: '/merchants/all',
        value: [['companies:brand-search'], ['companies:brand-create']],
    },
    {
        key: '/transactions/all',
        value: [['charges:brand-search']],
    },
    {
        key: '/transactions/report-history',
        value: [['resource-reports:brand-search']],
    },
    {
        key: '/transactions/report-delivery',
        value: [['resource-reports:brand-create']],
    },
    {
        key: '/fee-profile/all',
        value: [['fee-profiles:brand-search']],
    },
    {
        key: '/fee-profile/create',
        value: [['fee-profiles:brand-modify']],
    },
    {
        key: '/brands/all',
        value: [['brands:global-search']],
    },
    {
        key: '/limit-profiles/all',
        value: [['limit-profiles:global-search'], ['limit-profiles:global-create']],
    },
];
