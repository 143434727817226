import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ModalShowable } from '../../../interfaces/modal-showable.interface';

@Component({
    selector: 'modal-info',
    templateUrl: './modal-info.component.html',
    styleUrls: ['./modal-info.component.scss'],
})
export class ModalInfoComponent implements ModalShowable {
    @Input() isClosable = true;

    showed: boolean;

    @Input() header: string;
    @Input() message: string;
    @Input() okButton: string;
    @Input() positionTop?: number;
    @Input() width?: number;
    @Output() accept = new EventEmitter<void>();

    closeModal(): void {
        this.showed = false;
    }

    show(): void {
        this.showed = true;
    }
}
