import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

import { MESSAGE_TYPE, MessagePopupService, Toastr } from '../../../services/message-popup.service';

@Component({
    selector: 'app-message-panel',
    templateUrl: './message-panel.component.html',
    styleUrls: ['./message-panel.component.scss'],
    animations: [
        trigger('closeToaster', [
            transition('* => void', [
                animate(
                    '.5s linear',
                    style({
                        height: 0,
                    }),
                ),
            ]),
            transition(':leave', [query('@', animateChild())]),
        ]),
    ],
})
export class MessagePanelComponent implements OnInit {
    readonly MESSAGE_TYPE = MESSAGE_TYPE;

    toasters: Toastr[] = [];

    constructor(private messagePopupService: MessagePopupService) {}

    ngOnInit(): void {
        this.messagePopupService.toastrs.subscribe((toastr: Toastr) => {
            const timerId = window.setTimeout(() => {
                this.toasters = this.toasters.filter(x => x.id !== toastr.id);
                this.messagePopupService.clear(timerId);
            }, toastr.delay);
            toastr.timerId = timerId;
            this.toasters.push(toastr);
        });
    }

    public toasterById = (_id: number, item: Toastr) => item.id;

    onClosed(toastr: Toastr): void {
        this.toasters = this.toasters.filter(x => x.id !== toastr.id);
        window.clearTimeout(toastr.timerId);
    }
}
