import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ReloadListService {
    private loadersList: string[] = [];
    changeState$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    reload(id: string): void {
        if (this.loadersList.indexOf(id) !== -1) this.changeState$.next(!!this.loadersList.length);
    }

    push(id: string): BehaviorSubject<boolean> {
        this.loadersList.push(id);
        return this.changeState$;
    }

    unsubscribe(id: string): void {
        if (this.loadersList.indexOf(id) !== -1) this.loadersList.splice(this.loadersList.indexOf(id), 1);
    }
}
