import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/modules/main/providers/app.config';
import { CookieValue, run, show } from 'vanilla-cookieconsent';

import { CookieConsentConfigBuilder } from './cookie-consent-config.builder';
import type { CookieCategory, UserConsentValue } from './types';

@Injectable({
    providedIn: 'root',
})
export class CookieConsentService {
    private configBuilder: CookieConsentConfigBuilder;

    public readonly userConsent$ = new Subject<UserConsentValue>();

    constructor(
        @Inject(APP_CONFIG)
        private readonly appConfig: AppConfig,
    ) {
        this.configBuilder = CookieConsentConfigBuilder.create();
    }

    get canShowCookieConsent() {
        return this.appConfig.cookieConsent === true;
    }

    public init() {
        const config = this.configBuilder
            .addTranslation('en', './assets/locales/en.json')
            .addAnalytics({
                id: 'nreum',
                label: 'New Relic Browser Agent',
                cookies: [
                    {
                        name: /^(jsessionid|nragent|nreum)$/i,
                    },
                ],
            })
            .addCallback('onConsent', ({ cookie }) => {
                this.emitConsentChanged(cookie);
            })
            .addCallback('onChange', ({ cookie, changedCategories: _cc, changedServices: _cs }) => {
                this.emitConsentChanged(cookie);
            })
            .addAutoShow(this.canShowCookieConsent)
            .build();

        run(config);
    }

    public showConsentModal() {
        if (this.canShowCookieConsent) {
            // Explicit true is required for "re-openings" of modal
            show(true);
        }
    }

    private emitConsentChanged(cookie: CookieValue) {
        const containsCategory = (category: CookieCategory) => cookie.categories.includes(category);

        const nextValue = {
            necessary: true,
            analytics: containsCategory('analytics'),
            marketing: containsCategory('marketing'),
            preference: containsCategory('preference'),
            services: cookie.services as UserConsentValue['services'],
        } as const;

        this.userConsent$.next(nextValue);
    }
}
