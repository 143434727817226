import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SidebarMenuService {
    private _isSidebarClosed: boolean;

    get isSidebarClosed(): boolean {
        return this._isSidebarClosed;
    }

    set setSidebarState(value: boolean) {
        this._isSidebarClosed = value;
    }
}
