<div class="full-width row">
    <div class="col-2 sidebar-wrapp hide-on-print" *ngIf="isSidebarShowed">
        <app-sidebar></app-sidebar>
    </div>
    <div class="col-md-12 col-lg-10 pr-0 pl-0 main-content">
        <app-bulletin></app-bulletin>
        <div class="content-wrapp">
            <app-topbar (burgerToggle)="onToggle($event)"></app-topbar>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
