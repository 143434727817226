import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MESSAGE_TYPE, Toastr } from '../../../../services/message-popup.service';

@Component({
    selector: 'app-toastr-message',
    templateUrl: './toastr-message.component.html',
    styleUrls: ['./toastr-message.component.scss'],
})
export class ToastrMessageComponent {
    @Input() toaster: Toastr;
    @Output() closed = new EventEmitter<Toastr>();

    readonly MESSAGE_TYPE = MESSAGE_TYPE;

    closeModal(): void {
        this.closed.emit(this.toaster);
    }

    isPeriodShowable(detail: string): boolean {
        return !detail.includes('.');
    }
}
