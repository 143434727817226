import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AccessService } from '../../access/services/access.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private accessService: AccessService, private router: Router) {}

    canActivate(
        _next: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.accessService.isAccessExist()) {
            const destinationUrl = this.accessService.getDestinationUrl();
            window.location.href = destinationUrl ? destinationUrl : '/v2/auth/sign-in';
            return false;
        } else if (this.accessService.isAccessExist() && this.accessService.isTfaRequired()) {
            this.router.navigate(['auth', 'tfa-sign-in']);
            return false;
        }
        return true;
    }
}
