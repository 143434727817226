import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ContentTypeHeaderInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const newReq = req.clone({
            headers: req.headers.set('Content-Type', 'application/json'),
        });

        return next.handle(newReq);
    }
}
