import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ClickOutsideDirective } from './click-outside.directive';
import { DynamicAttributeDirective } from './dynamic-attribute.directive';
import { IfSharedDirective } from './if-shared.directive';
import { LuhnValidatorDirective } from './luhn-validator.directive';
import { OuterLinkDirective } from './outer-link.directive';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [
        ClickOutsideDirective,
        DynamicAttributeDirective,
        IfSharedDirective,
        LuhnValidatorDirective,
        OuterLinkDirective,
    ],
    exports: [
        ClickOutsideDirective,
        DynamicAttributeDirective,
        IfSharedDirective,
        LuhnValidatorDirective,
        OuterLinkDirective,
    ],
})
export class MainDirectiveModule {}
