import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import * as fastLuhn from 'fast-luhn';

import { REGEX } from '../helper/constants/regex.constants';

/**
 * PAN detection requirements:
 * - The system should disregard any number of dashes and spaces and combine the digits into one number.
 * - If there is a number ≥ 13 and ≤ 19 digits, excluding dashes (-) and spaces ( ), the system will take this number and check it for PAN exposure using the Luhn algorithm.
 * - If there is a number with > 19 digits, excluding dashes (-) and spaces ( ), the system will take the first 19 digits and will check it for PAN exposure using the Luhn algorithm.
 */
function detectPan(value: string): boolean {
    if (!value) return false;

    const numericStringMatches = value.match(REGEX.PAN_PATTERN); // matches groups of 13 or more digits with spaces or dashes in between

    if (!numericStringMatches) return false;

    return numericStringMatches.some(numericStringMatch => {
        const numericString = numericStringMatch.replace(/[ -]/g, ''); // Remove spaces and dashes

        if (!numericString.length || numericString.length < 13) return false;
        else if (numericString.length >= 13 && numericString.length <= 19)
            return fastLuhn(numericString.slice(0, numericString.length));
        else if (numericString.length > 19) return fastLuhn(numericString.slice(0, 19));
    });
}

@Directive({
    selector: '[panForbidden]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: PanForbiddenValidator,
            multi: true,
        },
    ],
})
export class PanForbiddenValidator implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        const panDetected = detectPan(control.value);

        return panDetected ? { panForbidden: true } : null;
    }
}
