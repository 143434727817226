import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { AbstractControl, NgModel } from '@angular/forms';

import { FormErrorComponent } from '../form-error/form-error.component';

@Component({
    selector: 'form-errors',
    templateUrl: './form-errors.component.html',
    styleUrls: ['./form-errors.component.scss'],
})
export class FormErrorsComponent {
    @Input() errors: { [key: string]: string };
    @Input() field: NgModel | AbstractControl;

    @ContentChildren(FormErrorComponent) errorList: QueryList<FormErrorComponent>;
}
