import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { environment } from '../../../environments/environment';
import { BRAND } from '../helper/constants/brand.constants';

@Directive({
    selector: '[showBrand]',
})
export class ShowBrandDirective implements OnInit {
    private hasView = false;
    @Input() showBrand = BRAND.PAYDOCK;

    constructor(private templateRef: TemplateRef<any>, private vcr: ViewContainerRef) {}

    ngOnInit() {
        const isActualBrand = this.showBrand === environment.brand;
        if (isActualBrand && !this.hasView) {
            this.vcr.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!isActualBrand && this.hasView) {
            this.vcr.clear();
            this.hasView = false;
        }
    }
}
