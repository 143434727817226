<app-ajax-loader></app-ajax-loader>
<app-message-panel></app-message-panel>
<app-ajax-loader *ngIf="showLoader"></app-ajax-loader>
<router-outlet></router-outlet>
<app-progress-bar></app-progress-bar>
<modal-info
    #modalInfo
    [header]="'Password Reset'"
    [message]="
        'Your password will expire on ' +
        expiryDate +
        '. Please reset your password now in order to retain access to your account'
    "
    [okButton]="'Reset'"
    [positionTop]="100"
    [width]="450"
    (accept)="onAccept()"></modal-info>

<modal-info
    #noPermissionModal
    [isClosable]="false"
    [header]="'Redirecting'"
    [message]="'You do not have permissions to view this page. Please contact your admin.'"
    [okButton]="'Ok'"></modal-info>
