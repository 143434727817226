<div
    class="toastr"
    [class.error]="toaster.type | equalMessageType : MESSAGE_TYPE.ERROR"
    [class.success]="toaster.type | equalMessageType : MESSAGE_TYPE.SUCCESS"
    [class.info]="toaster.type | equalMessageType : MESSAGE_TYPE.INFO"
    [class.warning]="toaster.type | equalMessageType : MESSAGE_TYPE.WARNING">
    <div class="icon-wrap">
        <i
            class="fa"
            [class.fa-check]="toaster.type | equalMessageType : MESSAGE_TYPE.SUCCESS"
            [class.fa-times]="toaster.type | equalMessageType : MESSAGE_TYPE.ERROR"
            [class.info]="toaster.type | equalMessageType : MESSAGE_TYPE.INFO"
            [class.fa-exclamation-triangle]="toaster.type | equalMessageType : MESSAGE_TYPE.WARNING"></i>
    </div>
    <div class="toastr__content">
        <span class="text-break" *ngIf="toaster.message">{{ toaster.message }}</span>
        <ng-container *ngIf="toaster.collectionDetails?.length">
            <span *ngFor="let detail of toaster.collectionDetails">
                {{ detail }}<span *ngIf="isPeriodShowable(detail)">.</span>
            </span>
        </ng-container>
    </div>
    <i class="fa fa-times" (click)="closeModal()"></i>
</div>
