import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

interface DynamicAttributeConfig {
    selector: string;
    attribute: string;
    attributeValue: string;
}

@Directive({
    selector: '[dynamicAttribute]',
})
export class DynamicAttributeDirective implements AfterViewInit {
    @Input() dynamicAttributeConfig: DynamicAttributeConfig[] = [];

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit(): void {
        this.attachAttribute();
    }

    attachAttribute() {
        this.dynamicAttributeConfig.forEach(({ selector, attribute, attributeValue }) => {
            const element = this.elementRef.nativeElement.querySelector(selector);

            if (!element) return;

            this.renderer.setAttribute(element, attribute, attributeValue);
        });
    }
}
