import { Injectable } from '@angular/core';
import { chain, includes } from 'lodash';

import { ACCESS_STORAGE_KEY } from '../helper/constants/local-storage.constant';

export interface SharedPrivileges {
    privilege: string;
    companies: string[];
}

export interface SharedCompaniesData {
    _id: string;
    company_name: string;
}

export interface SharedPermissions {
    permission: string;
    companies: string[];
}

export enum SHARED_TYPE {
    PRIVILEGE = 'privilege',
    PERMISSION = 'permission',
}

export interface SharedGroupComponent {
    shared_permissions: SharedPermissions[];
    shared_privileges: SharedPrivileges[];
}

@Injectable()
export class SharedGroupService {
    public static getCompaniesByPermission(permission: string): string[] {
        const shared: SharedGroupComponent = JSON.parse(localStorage.getItem(ACCESS_STORAGE_KEY.SHARED));
        return chain(shared).get('shared_permissions').find({ permission }).get('companies', []).value();
    }

    public static getCompaniesByPrivilege(privilege: string): string[] {
        const shared: SharedGroupComponent = JSON.parse(localStorage.getItem(ACCESS_STORAGE_KEY.SHARED));
        return chain(shared).get('shared_privileges').find({ privilege }).get('companies', []).value();
    }

    public static isShared(companyId: string, property: string, sharedType: SHARED_TYPE): boolean {
        return includes(
            sharedType === SHARED_TYPE.PERMISSION
                ? SharedGroupService.getCompaniesByPermission(property)
                : SharedGroupService.getCompaniesByPrivilege(property),
            companyId,
        );
    }

    public static getSharedCompaniesData(): SharedCompaniesData[] {
        return JSON.parse(localStorage.getItem(ACCESS_STORAGE_KEY.SHARED_COMPANIES));
    }

    public static deleteShared() {
        Object.values([ACCESS_STORAGE_KEY.SHARED_COMPANIES, ACCESS_STORAGE_KEY.SHARED]).forEach(key =>
            localStorage.removeItem(key),
        );
    }

    public static updateSharedComponent(
        sharedPermissions: SharedPermissions[],
        sharedPrivileges: SharedPrivileges[],
    ): void {
        this.deleteShared();
        window.localStorage.setItem(
            'shared',
            JSON.stringify({
                ...(sharedPermissions ? { shared_permissions: sharedPermissions } : {}),
                ...(sharedPrivileges ? { shared_privileges: sharedPrivileges } : {}),
            }),
        );
    }

    public static updateSharedCompaniesData(companiesData: SharedCompaniesData[]): void {
        window.localStorage.setItem('shared_companies', JSON.stringify(companiesData));
    }
}
