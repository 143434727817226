import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
    isClosed = true;

    @Output() burgerToggle = new EventEmitter<boolean>();

    @HostListener('window:resize')
    onResize() {
        const isSidebarNeeded = window.innerWidth > 992;
        if (isSidebarNeeded) {
            this.burgerToggle.emit(true);
            this.isClosed = false;
        }
        this.reflowCharts();
    }

    ngOnInit(): void {
        this.isClosed = window.innerWidth < 992;
        this.burgerToggle.emit(!this.isClosed);
    }

    showSidebar(): void {
        this.isClosed = !this.isClosed;
        this.burgerToggle.emit(!this.isClosed);
        this.reflowCharts();
    }

    reflowCharts(): void {
        Highcharts.charts.forEach(chart => {
            const timeoutId = setTimeout(() => {
                if (chart) chart.reflow();
                clearTimeout(timeoutId);
            });
        });
    }
}
