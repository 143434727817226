import { Ajax } from '@newrelic/browser-agent/features/ajax';
import { JSErrors } from '@newrelic/browser-agent/features/jserrors';
import { Metrics } from '@newrelic/browser-agent/features/metrics';
import { PageAction } from '@newrelic/browser-agent/features/page_action';
import { PageViewEvent } from '@newrelic/browser-agent/features/page_view_event';
import { PageViewTiming } from '@newrelic/browser-agent/features/page_view_timing';
import { SessionTrace } from '@newrelic/browser-agent/features/session_trace';
import { Spa } from '@newrelic/browser-agent/features/spa';
import { Agent } from '@newrelic/browser-agent/loaders/agent';

/**
 * !Warning: if we ever want to use to enable `SessionReplay` in config we need to use BrowserAgent
 */
export class AnalyticsAgent extends Agent {
    /** Used to prevent `disableNonEssentialFeatures` from causing an `infinite` reload bomb */
    private instrumentingNonEssentials: boolean;

    constructor(options: Record<string, unknown>, agentId: string) {
        super(
            {
                ...options,
                features: [Ajax, JSErrors, Metrics, PageAction, PageViewEvent, PageViewTiming, SessionTrace, Spa],
                loaderType: 'browser-agent',
            },
            agentId,
        );

        this.instrumentingNonEssentials = false;
    }

    public enableFeatures() {
        this.instrumentingNonEssentials = true;

        this.start();

        return this;
    }

    public disableNonEssentialFeatures() {
        if (this.instrumentingNonEssentials && typeof window !== 'undefined') {
            window.location.reload();
        }
    }
}
