<div class="bulletin-box" *ngIf="bulletins">
    <ng-container *ngFor="let bulletin of bulletins">
        <div class="bulletin" *ngIf="bulletin.read === false">
            <i
                class="fa fa-times"
                *ngIf="bulletin.global === false"
                (click)="deleteBulletin(bulletin._id); bulletin.read = true"></i>
            <div>{{ bulletin.body }}</div>
        </div>
    </ng-container>
</div>
