import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiModule } from '../../api.module';
import { Resource } from '../resource';
import { Search } from '../search.interface';
import { Company, CompanyCreate, UpdateCompany } from './company.interface';

const ROUTE = {
    SEARCH_MERCHANTS: '/scope/brand/companies',
    CREATE_MERCHANT: '/scope/brand/companies',
    UPDATE_MERCHANT: '/scope/brand/companies/:id',
    DELETE_MERCHANT: '/scope/brand/companies/:id',
    ACTIVATE_SIGN_IN_MERCHANT: '/scope/brand/companies/:id/activate-sign-in',
    DEACTIVATE_SIGN_IN_MERCHANT: '/scope/brand/companies/:id/deactivate-sign-in',
    UPDATE: '/companies/:id',
    GET: '/companies/:id',
    SEARCH: '/companies',
    CREATE: '/companies',
};

@Injectable({
    providedIn: ApiModule,
})
export class CompanyService extends Resource {
    getCurrent(): Observable<Company> {
        return this.http.get<Company>(this.getLink(ROUTE.GET, { id: 'current' }));
    }

    searchMerchants(query: Record<string, unknown>): Observable<Search<Company[]>> {
        const params = new HttpParams().set('isSearch', 'true');
        return this.http.get<Search<Company[]>>(this.getLink(ROUTE.SEARCH_MERCHANTS, query), { params });
    }

    createMerchant(company: CompanyCreate): Observable<Company> {
        return this.http.post<Company>(this.getLink(ROUTE.CREATE_MERCHANT), company);
    }

    search(query: Record<string, unknown>): Observable<Search<Company[]>> {
        const params = new HttpParams().set('isSearch', 'true');
        return this.http.get<Search<Company[]>>(this.getLink(ROUTE.SEARCH, query), { params });
    }

    create(company: Company): Observable<Company> {
        return this.http.post<Company>(this.getLink(ROUTE.CREATE), company);
    }

    updateMerchant(id: string, company: UpdateCompany): Observable<Company> {
        return this.http.put<Company>(this.getLink(ROUTE.UPDATE_MERCHANT, { id }), company);
    }

    deleteMerchant(id: string): Observable<Company> {
        return this.http.delete<Company>(this.getLink(ROUTE.DELETE_MERCHANT, { id }));
    }

    activateMerchant(id: string): Observable<void> {
        return this.http.post<void>(this.getLink(ROUTE.ACTIVATE_SIGN_IN_MERCHANT, { id }), null);
    }

    deactivateMerchant(id: string): Observable<void> {
        return this.http.post<void>(this.getLink(ROUTE.DEACTIVATE_SIGN_IN_MERCHANT, { id }), null);
    }
}
