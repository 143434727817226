<ng-container *ngFor="let item of items; index as i">
    <app-custom-fields
        [customField]="item"
        [isHiddenRemoveButton]="items.length <= 1"
        (newCustomField)="newEntry($event)"
        (deleteCustomField)="deleteEntry($event)"
        (changeCustomField)="discloseValidatorChange()"
        [possibleFields]="possibleFields"
        [keyPatternValidation]="keyPatternValidation">
    </app-custom-fields>
</ng-container>
