import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgProgressModule } from 'ngx-progressbar';
import { MessageService } from 'primeng/api';
import { APP_CONFIG, getAppConfig } from 'src/modules/main/providers/app.config';

import { AccessModule } from '../access/access.module';
import { AnalyticsModule } from '../analytics/analytics.module';
import { ApiModule } from '../api/api.module';
import { ReloadListService } from '../list/services/reload-list.service';
import { AppComponent } from './components/app/app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { MainDirectiveModule } from './directive/main-directive.module';
import { MainRoutingModule } from './main-routing.module';
import { MainSharedModule } from './main-shared.module';
import { MessageParseService } from './services/message-parse-service';

@NgModule({
    declarations: [AppComponent, DashboardComponent, SidebarComponent, TopbarComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AnalyticsModule,
        HttpClientModule,
        MainRoutingModule,
        ApiModule,
        AccessModule,
        MainSharedModule,
        MainDirectiveModule,
        NgProgressModule,
    ],
    providers: [
        MessageService,
        MessageParseService,
        ReloadListService,
        { provide: Window, useValue: window },
        { provide: APP_CONFIG, useFactory: getAppConfig },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
