import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ApiModule } from '../../api.module';
import { Resource } from '../resource';
import { Search } from '../search.interface';
import { AvailableSchemaRegistries, Schema, SchemaRegistries } from './schema-registries.interface';

const ROUTE = {
    SEARCH: '/scope/global/schema-registries',
    SEARCH_AVAILABLE: '/scope/global/schema-registries/available',
    APPLY: '/scope/global/schema-registries/:id/apply',
    ROLLBACK: '/scope/global/schema-registries/:id/rollback',
    EXTRACT_UI_SCHEMAS: '/settings/ui-schemas.json',
};

@Injectable({
    providedIn: ApiModule,
})
export class SchemaRegistriesService extends Resource {
    public searchAvailable(query?: Record<string, unknown>): Observable<Search<AvailableSchemaRegistries[]>> {
        const params = new HttpParams().set('isSearch', 'true');
        return this.http.get<Search<[]>>(this.getLink(ROUTE.SEARCH_AVAILABLE, query), { params });
    }
    public search(query?: Record<string, unknown>): Observable<Search<SchemaRegistries[]>> {
        const params = new HttpParams().set('isSearch', 'true');
        return this.http.get<Search<[]>>(this.getLink(ROUTE.SEARCH, query), { params });
    }

    public apply(sourceId: string, body: { hostname: string }): Observable<SchemaRegistries> {
        return this.http.post<SchemaRegistries>(this.getLink(ROUTE.APPLY, { id: sourceId }), body);
    }
    public rollback(sourceId: string, body: { version: number }): Observable<SchemaRegistries> {
        return this.http.post<SchemaRegistries>(this.getLink(ROUTE.ROLLBACK, { id: sourceId }), body);
    }
    extractUiSchemas(): Observable<Schema[]> {
        const params = new HttpParams().set('cache-control', 'no-cache');
        return this.http.get<Schema[]>(this.generateS3Url(), { params });
    }

    generateS3Url() {
        return `${environment.BASE_URL.ASSETS}${environment.ENV_NAME}/${this.extractHostName(
            environment.BASE_URL.API,
        )}${ROUTE.EXTRACT_UI_SCHEMAS}`;
    }
}
