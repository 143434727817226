import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { includes } from 'lodash';

import { environment } from '../../../environments/environment';
import { LOCALHOST, Uri } from '../../main/helper/uri';

export const LINK_TYPE = {
    API: 'api',
    FRONT: 'front',
    ASSETS: 'assets',
};

@Injectable()
export abstract class Resource {
    constructor(protected http: HttpClient) {}

    protected getUri(route: string, linkType: string): string {
        const alias = Uri.extractAppAlias(document.location.hostname, environment.countSubDomain);
        switch (linkType) {
            case LINK_TYPE.API:
                return `${this.convertUrl(environment.BASE_URL.API, alias)}v1${route}`;
            case LINK_TYPE.FRONT:
                return `${this.convertUrl(environment.BASE_URL.FRONT, alias)}${route}`;
            case LINK_TYPE.ASSETS:
                return `${environment.BASE_URL.ASSETS}${route}`;
        }
        return `${this.convertUrl(environment.BASE_URL.API, alias)}v1${route}`;
    }

    protected getLink(route: string, params: object = {}, linkType = ''): string {
        for (const key in params) {
            if (includes(route, `:${key}`)) {
                route = route.replace(`:${key}`, params[key]);
                delete params[key];
            }
        }
        return this.getUri(Uri.updateQueryParams(route, params), linkType);
    }

    protected convertUrl(url: string, alias: string): string {
        return environment.DEFAULT_ALIAS
            ? url.replace('{alias}', alias !== LOCALHOST ? alias : environment.DEFAULT_ALIAS)
            : url;
    }
    public extractHostName(url): string {
        const alias = Uri.extractAppAlias(document.location.hostname, environment.countSubDomain);
        return this.convertUrl(url, alias)
            .replace(/^https?:\/\//, '')
            .replace(/\//, '');
    }
}
