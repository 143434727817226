type NewrelicConfigParams =
    | {
          enabled?: true;
          accountId: string;
          agentId: string;
          applicationId: string;
          trustKey: string;
          licenseKey: string;
      }
    | {
          enabled: false;
          accountId?: string;
          agentId?: string;
          applicationId?: string;
          licenseKey?: string;
          trustKey?: string;
      };

export function createNewrelicConfig({
    accountId,
    agentId,
    applicationId,
    enabled = true,
    licenseKey,
    trustKey,
}: NewrelicConfigParams) {
    const agentID = enabled ? agentId : '__local_agent__';
    const applicationID = enabled ? applicationId : '__local_application__';
    const newrelicGateway = 'bam.nr-data.net';

    const disabled = () => ({
        autoStart: false,
        enabled: false,
    });

    const necessary = (config: Record<string, unknown> = {}) => ({
        autoStart: true,
        enabled: true,
        ...config,
    });

    const optIn = () => ({
        autoStart: false,
        enabled: true,
    });

    return {
        browserAgent: {
            enabled,
            options: {
                info: {
                    applicationID,
                    licenseKey,
                    beacon: newrelicGateway,
                    errorBeacon: newrelicGateway,
                    sa: 1,
                },
                init: {
                    /**
                     * Disabling cookies makes Newrelic runs with good privacy defaults.
                     * We still need to use our own cookie consent to deal with
                     * variations in laws across different countries/continents
                     * and out of respect for the customer.
                     */
                    privacy: {
                        cookies_enabled: false,
                    },
                    ajax: necessary({
                        deny_list: [newrelicGateway],
                    }),
                    distributed_tracing: optIn(),
                    jserrors: necessary(),
                    metrics: optIn(),
                    page_action: optIn(),
                    page_view_event: necessary(),
                    page_view_timing: optIn(),
                    session_replay: disabled(),
                    session_trace: optIn(),
                    spa: optIn(),
                },
                loaderConfig: {
                    accountID: accountId,
                    agentID,
                    applicationID,
                    licenseKey,
                    trustKey,
                },
            },
        },
    } as const;
}
