import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { get, some } from 'lodash';

import { Charge } from '../../../../api/resources/charge/charge.interface';
import { ChargeService } from '../../../../api/resources/charge/charge.service';
import { Search } from '../../../../api/resources/search.interface';
import { REGEX } from '../../../helper/constants/regex.constants';

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomFieldsListComponent),
    multi: true,
};

export const CUSTOM_INPUT_CONTROL_VALUE_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CustomFieldsListComponent),
    multi: true,
};

export interface CustomField {
    key: string;
    value: string;
}

@Component({
    selector: 'app-custom-fields-list',
    templateUrl: './custom-fields-list.component.html',
    styleUrls: ['./custom-fields-list.component.scss'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALUE_VALIDATOR],
})
export class CustomFieldsListComponent implements ControlValueAccessor, Validator, OnInit {
    readonly REGEX = REGEX;

    possibleFields: string[];

    @Input() isNew: boolean;
    @Input() keyPatternValidation = true;

    public items = [] as CustomField[];
    private onTouchedCallback: () => void = noop;
    public discloseValidatorChange: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    constructor(private chargeService: ChargeService) {}

    get value(): CustomField[] {
        return this.items;
    }

    set value(items: CustomField[]) {
        this.onChangeCallback(items);
        this.discloseValidatorChange();
    }

    ngOnInit(): void {
        this.chargeService
            .search({ limit: 1, expand: 'possible_custom_transaction_fields' })
            .subscribe((res: Search<Charge[]>) => {
                this.possibleFields = get(res, 'info.possible_custom_transaction_fields', []);
            });
    }

    newEntry(item: CustomField): void {
        const index = this.items.findIndex(existItem => existItem === item);

        if (index > -1) this.items.splice(index, 1, item);
        else this.items.splice(0, 0, item);

        this.value = this.items;
    }

    deleteEntry(item: CustomField): void {
        const index = this.items.findIndex(existItem => existItem === item);
        this.items.splice(index, 1);
        this.value = this.items;
    }

    change(): void {
        this.onTouchedCallback();
    }

    writeValue(list: CustomField[]): void {
        if (list !== this.items) this.items = list;
    }

    registerOnChange(fn: (list: CustomField[]) => void): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    registerOnValidatorChange(fn: () => void): void {
        this.discloseValidatorChange = fn;
    }

    validate(_control: AbstractControl): ValidationErrors | null {
        const isInvalid = some(this.items, this.validateCustomField.bind(this));
        return isInvalid ? { invalid: true } : null;
    }

    validateCustomField(customField: CustomField): boolean {
        return (
            this.customFieldBothRequiredValidation(customField.value, customField.key) ||
            this.customFieldLengthValidation(customField.key, 2, 40) ||
            this.customFieldLengthValidation(customField.value, 2, 128) ||
            this.customFieldKeyPatternValidation(customField.key)
        );
    }

    customFieldLengthValidation(text: string, minlength: number, maxlength: number): boolean {
        return !!text && (text.length < minlength || text.length > maxlength);
    }

    customFieldBothRequiredValidation(key: string, value: string): boolean {
        return (!!key && !value) || (!key && !!value);
    }

    customFieldKeyPatternValidation(key: string): boolean {
        return this.keyPatternValidation && !!key && !key.match(REGEX.OBJECT_KEY);
    }
}
