import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AccessTokenInterceptor } from './interceptors/access-token.interceptor';
import { ContentTypeHeaderInterceptor } from './interceptors/content-type-header.interceptor';
import { TransformResponseInterceptor } from './interceptors/transform-response.interceptor';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TransformResponseInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ContentTypeHeaderInterceptor, multi: true },
    ],
})
export class ApiModule {}
