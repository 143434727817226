<p-dialog
    [(visible)]="showed"
    [closable]="isClosable"
    [modal]="true"
    [positionTop]="positionTop"
    [style.width.px]="width"
    [style.minHeight.px]="120">
    <p-header>
        {{ header }}
    </p-header>
    <div class="content">
        <p>{{ message }}</p>
    </div>
    <p-footer>
        <div class="col-12 text-right p-0">
            <button *ngIf="okButton" class="btn btn-success" (click)="accept.emit(); closeModal()">
                {{ okButton }}
            </button>
        </div>
    </p-footer>
</p-dialog>
