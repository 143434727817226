import { Injectable } from '@angular/core';
import { get, has, isArray, isEmpty, isObject, isString } from 'lodash';

@Injectable()
export class MessageParseService {
    getMessage(message: string | object) {
        return isObject(message) && has(message, 'message') ? get(message, 'message') : message;
    }

    getCollectionDetails(message: string | object) {
        if (isObject(message) && (has(message, 'details') || has(message, 'error_details')))
            return this.getCollectionWithObject(message);
        else return [get(message, 'message')];
    }

    private getCollectionWithObject(message: string | object) {
        const collectionDetails = [];
        const details = get(message, 'details') || (get(message, 'error_details') as any);
        if (has(message, 'message')) collectionDetails.push(get(message, 'message'));

        if (isString(details)) collectionDetails.push(details);
        else if (isArray(details))
            for (const detail of details)
                if (detail.gateway_specific_description) collectionDetails.push(detail.gateway_specific_description);
                else if (detail.description) collectionDetails.push(detail.description);
                else collectionDetails.push(detail);
        else if (details.gateway_specific_description && !isEmpty(details.gateway_specific_description))
            collectionDetails.push(details.gateway_specific_description);
        else if (details.description && !isEmpty(details.description)) collectionDetails.push(details.description);

        if (has(details, 'messages'))
            for (const detailsMessage of get(details, 'messages'))
                if (isString(detailsMessage)) collectionDetails.push(detailsMessage);
                else if (
                    detailsMessage.gateway_specific_description &&
                    !isEmpty(detailsMessage.gateway_specific_description)
                )
                    collectionDetails.push(detailsMessage.gateway_specific_description);

        return collectionDetails;
    }
}
